import clsx from 'clsx'

type Props = Readonly<{
  icon: React.JSX.Element
  size?: 'sm' | 'md'
  variant?: 'normal' | 'alert'
}>

function FlatIcon({
  icon,
  size = 'md',
  variant = 'normal',
}: Props): React.JSX.Element {
  return (
    <span
      className={clsx(
        'inline-block rounded border border-tertiary-3',
        size === 'sm' && 'h-4 w-4 p-0.5',
        size === 'md' && 'h-8 w-8 p-1',
        variant === 'normal' && 'bg-primary-1',
        variant === 'alert' && 'bg-primary-2',
      )}
    >
      {icon}
    </span>
  )
}

export default FlatIcon
