import * as Sentry from '@sentry/react'
import React, {useEffect} from 'react'
import ReactDOM from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import App from './components/App.tsx'

import './index.css'

Sentry.init({
  dsn: 'https://6809b028e20772fa3e1bf7f20e0a18f6@o305090.ingest.us.sentry.io/4507232180109312',
  enabled: import.meta.env.PROD,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
})

const rootEl = document.getElementById('root')
if (rootEl == null) {
  throw new Error('Root element not found')
}

const root = ReactDOM.createRoot(rootEl)
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error occurred.</p>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
