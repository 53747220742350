type Props = React.ComponentProps<'svg'>

function MenuIcon(props: Props): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 30 30"
      {...props}
    >
      <g fill="currentColor" filter="url(#filter0_d_86_145)">
        <path d="M28 21H2v5h26v-5ZM28 13H2v5h26v-5ZM28 5H2v5h26V5Z" />
      </g>
      <defs>
        <filter
          id="filter0_d_86_145"
          width="26"
          height="22.5"
          x="2"
          y="5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_86_145"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_86_145"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default MenuIcon
