type Props = React.ComponentProps<'svg'>

function NotInvestigatedClueIcon(props: Props): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 19 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_388_612)">
        <path
          d="M0.739169 11.5029C0.48158 10.9029 0.291188 10.3248 0.167993 9.76843C0.0559976 9.2012 0 8.63942 0 8.08309C0 6.45775 0.408783 5.03966 1.22635 3.82883C2.05511 2.61801 3.18627 1.67989 4.61981 1.01448C6.05335 0.338159 7.68848 0 9.5252 0C11.4739 0 13.1538 0.349067 14.565 1.0472C15.9873 1.74534 17.0793 2.69436 17.8408 3.89428C18.6136 5.0942 19 6.44684 19 7.95219C19 9.0103 18.8264 9.92115 18.4792 10.6847C18.1432 11.4483 17.7009 12.1028 17.1521 12.6482C16.6145 13.1937 16.0433 13.6736 15.4386 14.0881C14.8338 14.4918 14.257 14.8735 13.7082 15.2335C13.1706 15.5826 12.7283 15.948 12.3811 16.3298C12.0451 16.7116 11.8771 17.1479 11.8771 17.6388V18.3424H6.68612V17.557C6.68612 16.5971 6.84851 15.7844 7.1733 15.119C7.50928 14.4536 7.93487 13.8754 8.45005 13.3846C8.96522 12.8937 9.514 12.4628 10.0964 12.0919C10.6788 11.7101 11.2275 11.3283 11.7427 10.9465C12.2691 10.5647 12.6947 10.1448 13.0195 9.68662C13.3554 9.21756 13.5234 8.66123 13.5234 8.01764C13.5234 7.40677 13.3666 6.84499 13.0531 6.3323C12.7395 5.81961 12.2803 5.41054 11.6755 5.10511C11.0819 4.78877 10.354 4.6306 9.4916 4.6306C8.69643 4.6306 7.98526 4.77241 7.35809 5.05602C6.73092 5.32873 6.23254 5.72689 5.86295 6.25049C5.50457 6.77409 5.32538 7.39586 5.32538 8.11582C5.32538 8.37762 5.35337 8.63942 5.40937 8.90122C5.47657 9.16302 5.57177 9.44118 5.69496 9.73571L0.739169 11.5029ZM6.31653 21.9276H12.2299V27H6.31653V21.9276Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_388_612"
          x="0"
          y="0"
          width="19"
          height="29"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_388_612"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_388_612"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default NotInvestigatedClueIcon
