import {useSearchParams} from 'react-router-dom'
import type Level from '../domain/level'
import CheckIcon from './CheckIcon'
import ClueIcon from './ClueIcon'
import IconButton from './IconButton'
import {useLevelState, useNextLevel} from './LevelStateProvider'
import Pointer from './Pointer'

type Props = Readonly<{
  level: Level
  clue: string
}> &
  React.ComponentProps<'button'>

function ClueButton({
  clue,
  level,
  disabled,
  ...props
}: Props): React.JSX.Element {
  const levelState = useLevelState(level)
  const nextLevel = useNextLevel()
  const levelComplete = levelState === 'COMPLETE'
  const [searchParams] = useSearchParams()
  const completed = searchParams.get('completed')
  const Icon = levelComplete ? CheckIcon : ClueIcon
  return (
    <>
      <IconButton
        icon={
          <Icon className="h-6 w-6 tall:h-[30px] tall:w-[30px]" aria-hidden />
        }
        label={clue}
        variant={levelComplete ? 'secondary' : 'primary'}
        disabled={disabled || levelComplete}
        {...props}
      />
      {completed == null && nextLevel === level && (
        <Pointer
          className="pointer-events-none absolute top-0 translate-x-4/5 translate-y-4/5"
          aria-hidden
        />
      )}
    </>
  )
}

export default ClueButton
