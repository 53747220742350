import {Helmet} from 'react-helmet-async'
import {useNavigate} from 'react-router-dom'
import shuffleIn from '../sounds/shuffleIn'
import Button from './Button'
import ClueHotspot from './ClueHotspot'
import ClueText from './ClueText'
import HelperText from './HelperText'
import LevelTutorialDialog from './LevelTutorialDialog'

function FieldPage(): React.JSX.Element {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>Field</title>
      </Helmet>
      <h1 className="sr-only">Field</h1>
      <div className="absolute bottom-0 w-full text-center">
        <HelperText>Tap on the CLUE icons to explore this scene.</HelperText>
      </div>
      <div className="absolute left-[31%] top-[36%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot
          clue="jasper-maskelyne"
          imageUrl="/jasper-maskelyne-clue.webp"
        >
          <p>
            ... is that the famous stage magician,{' '}
            <ClueText>Jasper Maskelyne</ClueText>?
          </p>
          <p>
            The US Army employed an unusual variety of artists as camouflage
            specialists and combat engineers. These included photographers,
            performers, fashion designers and more.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[6%] top-[87%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot clue="william-blass" imageUrl="/william-blass-clue.webp">
          <p>
            These dog tags belong to one &quot;
            <ClueText>William Blass</ClueText>.&quot;
          </p>
          <p>
            Isn&apos;t that the famous fashion designer? What skills would he be
            providing on the front?
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[45%] top-[75%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot clue="supply-crates" imageUrl="/supply-crates-clue.webp">
          <p>
            Crates and crates of <ClueText>surplus equipment</ClueText>. In
            large-scale conflicts like World War II, secure supply lines were as
            crucial as the fighting itself. The bigger the army, the more
            vulnerable it was to supply disruptions in food, medicine and other
            non-combat essentials.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[68%] top-[69%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot
          clue="first-us-army-group"
          imageUrl="/first-united-states-army-group-clue.webp"
        >
          <p>
            This patch indicates that the soldier belongs to the{' '}
            <ClueText>First United States Army Group</ClueText>.
          </p>
          <p>Something seems strange about that title.</p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[58%] top-[41%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot
          clue="inflatable-props"
          imageUrl="/inflatable-props-clue.webp"
        >
          <p>
            It turns out that most of the vehicles around this camp are just{' '}
            <ClueText>inflatable props</ClueText>!
          </p>
          <p>
            This particular piece is imitating an M4 Sherman, one of the most
            heavily used American tanks during World War II.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[21%] top-[79%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot
          clue="operation-fortitude"
          imageUrl="/operation-fortitude-clue.webp"
        >
          <p>
            There&apos;s a document here which seems to describe the unit&apos;s
            plan for something called &quot;
            <ClueText>Operation Fortitude</ClueText>.&quot;
          </p>
          <p>
            They seem to be heavily mobilizing near the front line, but have a
            curious order to avoid combat.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[92%] top-[65%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot
          clue="camouflage-engineers"
          imageUrl="/camouflage-engineers-clue.webp"
        >
          <p>
            This soldier is impeccably dressed and carries an air of authority.
            He&apos;s probably one of the{' '}
            <ClueText>Camouflage Engineers</ClueText>, but he&apos;s too busy
            coordinating efforts to talk.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute right-0 top-0 m-2 flex items-start gap-5">
        <Button
          onClick={() => {
            navigate('/field/letter')
            shuffleIn.play()
          }}
        >
          VIEW LETTER
        </Button>
      </div>
      <LevelTutorialDialog />
    </>
  )
}

export default FieldPage
