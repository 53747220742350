import {Helmet} from 'react-helmet-async'

function NotFoundPage(): React.JSX.Element {
  return (
    <main>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <h1>Not found</h1>
    </main>
  )
}

export default NotFoundPage
