import React from 'react'
import {useNavigate} from 'react-router-dom'
import type Level from '../domain/level'
import shuffleIn from '../sounds/shuffleIn'
import Button from './Button'
import Dialog from './Dialog'

type Props = Readonly<{
  level: Level
  imageUrl: string
  navigationUrl: string
  children: React.ReactNode
}>

const LevelDialog = React.forwardRef<HTMLDialogElement, Props>(
  ({imageUrl, navigationUrl, children}, ref): React.JSX.Element => {
    const navigate = useNavigate()

    return (
      <Dialog ref={ref}>
        <div className="mx-4 my-5">
          <hr className="border-tertiary-3 opacity-20" />
          <div className="mt-3 flex gap-5">
            <img
              className="h-32 object-contain tall:h-[180px]"
              alt=""
              src={imageUrl}
              width={142}
              height={180}
            />
            <div className="space-y-4 text-xs font-medium tracking-wider tall:text-sm">
              {children}
            </div>
          </div>
          <div className="mt-4 flex justify-between">
            <form method="dialog">
              <Button>CLOSE</Button>
            </form>
            <Button
              onClick={() => {
                navigate(navigationUrl)
                shuffleIn.play()
              }}
            >
              INVESTIGATE
            </Button>
          </div>
        </div>
      </Dialog>
    )
  },
)

LevelDialog.displayName = 'LevelDialog'

export default LevelDialog
