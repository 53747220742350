import {useNavigate} from 'react-router-dom'
import {CLUE_NAMES, type Clue as ClueT} from '../domain/clue'
import Clue from './Clue'
import ClueDialog from './ClueDialog'
import {useClueActions, useClueState} from './ClueStateProvider'

type Props = Readonly<{
  clue: ClueT
  imageUrl: string
  children: React.ReactNode
}>

function ClueHotspot({clue, imageUrl, children}: Props): React.JSX.Element {
  const navigate = useNavigate()
  const clueState = useClueState(clue) ?? 'NOT_INVESTIGATED'
  const {investigateClue} = useClueActions()
  const name = CLUE_NAMES[clue]
  return (
    <>
      <Clue
        name={name}
        state={clueState}
        onInvestigate={() => {
          navigate({search: `?clue=${clue}`}, {replace: true})
        }}
      />
      <ClueDialog clue={clue} imageUrl={imageUrl} onClose={investigateClue}>
        {children}
      </ClueDialog>
    </>
  )
}

export default ClueHotspot
