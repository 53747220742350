import React, {useEffect, useRef} from 'react'
import {Helmet} from 'react-helmet-async'
import {useSearchParams} from 'react-router-dom'
import useLoopingSound from '../hooks/useLoopingSound'
import ClueButton from './ClueButton'
import HelperText from './HelperText'
import InfoDialog from './InfoDialog'
import ItemHotspot from './ItemHotspot'
import LevelDialog from './LevelDialog'

const AMBIENCE_SRC = [
  '/sounds/attic-ambience.webm',
  '/sounds/attic-ambience.mp3',
]

function HubPage(): React.JSX.Element {
  const introDialogRef = useRef<HTMLDialogElement>(null)
  const briefingRoomDialogRef = useRef<HTMLDialogElement>(null)
  const fieldDialogRef = useRef<HTMLDialogElement>(null)
  const officeDialogRef = useRef<HTMLDialogElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const showTutorial = searchParams.has('tutorial')
  const completed = searchParams.get('completed')

  useEffect(() => {
    if (showTutorial) {
      introDialogRef.current?.showModal()
    }
  }, [showTutorial])

  useLoopingSound(AMBIENCE_SRC)

  useEffect(() => {
    function handleClose() {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params)
          newParams.delete('tutorial')
          return newParams
        },
        {replace: true},
      )
    }
    const dialogEl = introDialogRef.current
    if (dialogEl == null) {
      return
    }
    dialogEl.addEventListener('close', handleClose)
    return () => {
      dialogEl.removeEventListener('close', handleClose)
    }
  }, [setSearchParams])

  return (
    <>
      <Helmet>
        <title>Attic</title>
      </Helmet>
      <div className="absolute bottom-0 w-full text-center">
        <HelperText>
          {completed == null
            ? 'Tap on the CLUE icons to interact with objects.'
            : 'Tap on the item to reveal a new clue.'}
        </HelperText>
      </div>
      <div className="absolute left-[76%] top-[58%] -translate-x-1/2 -translate-y-1/2">
        <ClueButton
          level="briefing-room"
          clue="Letter on the shelf"
          disabled={completed != null}
          onClick={() => {
            briefingRoomDialogRef.current?.showModal()
          }}
        />
      </div>
      {completed === 'briefing-room' && (
        <div className="absolute left-[91.5%] top-[84%] -translate-x-1/2 -translate-y-1/2">
          <ItemHotspot
            level="briefing-room"
            name="Old folder"
            imageUrl="/old-folder.webp"
            pointerPosition="top-left"
          >
            <p>
              There&apos;s a file in an old folder here, some sort of military
              record for a soldier named &quot;R. York.&quot;
            </p>
            <p>Could this be Richard&apos;s surname?</p>
          </ItemHotspot>
        </div>
      )}
      <div className="absolute left-[43.25%] top-[39%] -translate-x-1/2 -translate-y-1/2">
        <ClueButton
          level="field"
          clue="Letters on the desk"
          disabled={completed != null}
          onClick={() => {
            fieldDialogRef.current?.showModal()
          }}
        />
      </div>
      {completed === 'field' && (
        <div className="absolute left-[79.25%] top-[36%] -translate-x-1/2 -translate-y-1/2">
          <ItemHotspot
            level="field"
            name="Scribbled note"
            imageUrl="/scribbled-note.webp"
            pointerPosition="top-left"
          >
            <p>
              There&apos;s a postal code scribbled on the board here. E17 -
              that&apos;s Walthamstow in London.
            </p>
          </ItemHotspot>
        </div>
      )}
      <div className="absolute left-[38%] top-[75%] -translate-x-1/2 -translate-y-1/2">
        <ClueButton
          level="office"
          clue="Letters on the floor"
          disabled={completed != null}
          onClick={() => {
            officeDialogRef.current?.showModal()
          }}
        />
      </div>
      {completed === 'office' && (
        <div className="absolute left-[14%] top-[54%] -translate-x-1/2 -translate-y-1/2">
          <ItemHotspot
            level="office"
            name="Phone book"
            imageUrl="/phone-book.webp"
            pointerPosition="top-right"
          >
            <p>
              There&apos;s an old British phone book here, filled with Grandpa
              Frank&apos;s annotations.
            </p>
            <p>
              Once I have enough information about his friend Richard, I can use
              this to find the correct phone number.
            </p>
          </ItemHotspot>
        </div>
      )}
      <InfoDialog ref={introDialogRef} imageUrl="/attic-intro.webp">
        <p>
          My great-grandfather, Frank, has recently passed on. I&apos;ve never
          been in his attic before … it&apos;s full of these old letters to
          &quot;Rich,&quot; reflecting on D-Day and World War II.
        </p>
        <p>
          I&apos;m going to use these letters to find the war buddy he never
          could.
        </p>
      </InfoDialog>
      <LevelDialog
        ref={briefingRoomDialogRef}
        level="briefing-room"
        imageUrl="/blanche-letter.webp"
        navigationUrl="/briefing-room/letter?tutorial"
      >
        <p>
          A letter entitled &quot;Blanche.&quot; Grandpa Frank says he
          discovered a secret British mission just before D-Day.
        </p>
        <p>What were they up to?</p>
      </LevelDialog>
      <LevelDialog
        ref={fieldDialogRef}
        level="field"
        imageUrl="/ghosts-letter.webp"
        navigationUrl="/field/letter?tutorial"
      >
        <p>A letter entitled &quot;Ghosts.&quot;</p>
        <p>
          Grandpa Frank met an American unit who had been all over the European
          theatre, but something was odd about this army ...
        </p>
        <p>What was going on?</p>
      </LevelDialog>
      <LevelDialog
        ref={officeDialogRef}
        level="office"
        imageUrl="/tuskegee-letter.webp"
        navigationUrl="/office/letter?tutorial"
      >
        <p>A letter entitled &quot;Tuskegee.&quot;</p>
        <p>
          Grandpa Frank talks about a group of African American pilots flying
          across Europe before D-Day.
        </p>
        <p>Why were they there?</p>
      </LevelDialog>
    </>
  )
}

export default HubPage
