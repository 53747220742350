import {forwardRef} from 'react'
import Button from './Button'
import Dialog from './Dialog'

type Props = Readonly<{
  imageUrl: string
  buttonText?: string
  children: React.ReactNode
}>

const InfoDialog = forwardRef<HTMLDialogElement, Props>(function InfoDialog(
  {imageUrl, buttonText = 'NEXT', children},
  ref,
): React.JSX.Element {
  return (
    <Dialog ref={ref}>
      <div className="mx-4 my-5">
        <hr className="border-tertiary-3 opacity-20" />
        <div className="mt-3 flex gap-5">
          <img
            className="h-32 object-contain tall:h-[180px]"
            alt=""
            src={imageUrl}
            width={142}
            height={180}
          />
          <div className="space-y-4 text-xs font-medium tracking-wider tall:text-sm">
            {children}
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <form method="dialog">
            <Button>{buttonText}</Button>
          </form>
        </div>
      </div>
    </Dialog>
  )
})

export default InfoDialog
