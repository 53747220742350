type ClueState =
  | 'NOT_INVESTIGATED'
  | 'INVESTIGATED'
  | 'TAGGED'
  | 'CHECKED_CORRECT'
  | 'CHECKED_INCORRECT'

function investigate(clueState: ClueState): ClueState {
  if (clueState === 'NOT_INVESTIGATED') {
    return 'INVESTIGATED'
  }
  return clueState
}

function tag(clueState: ClueState): ClueState {
  if (clueState === 'NOT_INVESTIGATED' || clueState === 'INVESTIGATED') {
    return 'TAGGED'
  }
  return clueState
}

function untag(clueState: ClueState): ClueState {
  if (clueState === 'TAGGED') {
    return 'INVESTIGATED'
  }
  return clueState
}

function tagged(clueState: ClueState): boolean {
  return clueState === 'TAGGED' || clueState === 'CHECKED_CORRECT'
}

function investigated(clueState: ClueState): boolean {
  return clueState !== 'NOT_INVESTIGATED'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function markCorrect(_clueState: ClueState): ClueState {
  return 'CHECKED_CORRECT'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function markIncorrect(_clueState: ClueState): ClueState {
  return 'CHECKED_INCORRECT'
}

function correct(clueState: ClueState): boolean {
  return clueState === 'CHECKED_CORRECT'
}

const ClueState = {
  correct,
  investigate,
  investigated,
  markCorrect,
  markIncorrect,
  tag,
  tagged,
  untag,
} as const

export default ClueState
