type Level = 'briefing-room' | 'field' | 'office'

export type LevelState = 'INCOMPLETE' | 'COMPLETE'

/**
 * Get the next level to suggest.
 */
function next(levelStates: Partial<Record<Level, LevelState>>): Level | null {
  if (levelStates['briefing-room'] !== 'COMPLETE') return 'briefing-room'
  if (levelStates['field'] !== 'COMPLETE') return 'field'
  if (levelStates['office'] !== 'COMPLETE') return 'office'
  return null
}

const Level = {
  next,
} as const

export default Level
