import {Helmet} from 'react-helmet-async'
import useRevealedClues from '../hooks/useRevealedClues'
import IndecipherableText from './IndecipherableText'
import LetterPage from './LetterPage'

function OfficeLetterPage(): React.JSX.Element {
  const [revealedClues, revealClues] = useRevealedClues()

  return (
    <>
      <Helmet>
        <title>Office Letter</title>
      </Helmet>
      <LetterPage
        level="office"
        title="Office Letter"
        levelPathname="/office"
        clues={[
          'air-bases',
          'ltc-benjamin-davis',
          'aviators-cap',
          'flying-cross',
          'p51-mustang',
          'west-point-graduate',
          'tuskegee-airmen',
        ]}
        correctAnswerText="Further in the letter, your great-grandfather laments the fact that he lost a note Richard had given him -- which apparently contained his address and phone number."
        onAnswer={revealClues}
      >
        <p>Dear Rich,</p>
        <p>
          Even at the worst of times, I found hope for the future. We were
          heartened by the news of a group of Black pilots flying Warhawks,
          Thunderbolts and of course, the Cadillac of the skies, the{' '}
          <IndecipherableText revealed={revealedClues.includes('p51-mustang')}>
            P51 Mustang
          </IndecipherableText>
          . One of their best aces was{' '}
          <IndecipherableText
            revealed={revealedClues.includes('ltc-benjamin-davis')}
          >
            Benjamin O. Davis Jr
          </IndecipherableText>
          .
        </p>
        <p>
          He wasn&apos;t your typical highfalutin{' '}
          <IndecipherableText
            revealed={revealedClues.includes('west-point-graduate')}
          >
            West Point graduate
          </IndecipherableText>
          , yet the many missions he and the Red Tails flew before and after
          D-Day cleared the skies for our invasion. After we landed in France,
          he earned a Distinguished{' '}
          <IndecipherableText revealed={revealedClues.includes('flying-cross')}>
            Flying Cross
          </IndecipherableText>{' '}
          for his bomber escorts into the heart of Germany itself.
        </p>
        <p>
          While it felt like everything was on our shoulders at Normandy, we
          have countless other brave fighters to thank for making that invasion
          even possible.
        </p>
        <p>
          Your Pal,
          <br />
          Frank &quot;The Yank&quot;
        </p>
      </LetterPage>
    </>
  )
}

export default OfficeLetterPage
