import * as Sentry from '@sentry/react'
import {useEffect, useState} from 'react'

export const SUPPORTS_FULLSCREEN =
  'requestFullscreen' in document.documentElement

function useFullscreen(): Readonly<
  [fullscreen: boolean, toggleFullscreen: () => void]
> {
  const [fullscreen, setFullscreen] = useState(
    document.fullscreenElement != null,
  )

  useEffect(() => {
    function handleFullscreenChange() {
      setFullscreen(document.fullscreenElement != null)
    }
    document.addEventListener('fullscreenchange', handleFullscreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])

  async function toggleFullscreen() {
    if (fullscreen) {
      return document.exitFullscreen()
    }
    const root = document.getElementById('root')
    if (root == null) {
      return
    }
    await root.requestFullscreen()
    if ('lock' in screen.orientation) {
      await screen.orientation.lock('landscape')
    }
  }

  return [
    fullscreen,
    () => {
      toggleFullscreen().catch((err: unknown) => {
        // Squash and log error.
        const notSupportedError =
          err instanceof Error && err.name === 'NotSupportedError'
        if (!notSupportedError) {
          // Report any error that is not a NotSupportedError to Sentry.
          Sentry.captureException(err)
        }
        console.error(err)
      })
    },
  ]
}

export default useFullscreen
