import {useEffect, useRef} from 'react'
import {useSearchParams} from 'react-router-dom'
import InfoDialog from './InfoDialog'

function LevelTutorialDialog(): React.JSX.Element {
  const ref = useRef<HTMLDialogElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const showTutorial = searchParams.has('tutorial')

  useEffect(() => {
    if (showTutorial) {
      ref.current?.showModal()
    }
  }, [showTutorial])

  useEffect(() => {
    function handleClose() {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params)
          newParams.delete('tutorial')
          return newParams
        },
        {replace: true},
      )
    }
    const dialogEl = ref.current
    if (dialogEl == null) {
      return
    }
    dialogEl.addEventListener('close', handleClose)
    return () => {
      dialogEl.removeEventListener('close', handleClose)
    }
  }, [setSearchParams])

  return (
    <InfoDialog ref={ref} imageUrl="/level-tutorial.webp">
      <p>
        Inspect the items in this scene and gather CLUES to complete your
        great-grandfather&apos;s story.
      </p>
      <p>
        You can use the VIEW LETTER button to attempt a solution at any time.
      </p>
    </InfoDialog>
  )
}

export default LevelTutorialDialog
