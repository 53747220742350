import * as Sentry from '@sentry/browser'

function handleAudioLoadError(_soundId: number, error: unknown): void {
  if (typeof error === 'number') {
    error = getMediaErrorMessage(error)
  }
  Sentry.captureException(error)
  console.error('Failed to load sound', error)
}

function getMediaErrorMessage(code: number) {
  if (code === MediaError.MEDIA_ERR_ABORTED) {
    return 'The user canceled the audio.'
  }
  if (code === MediaError.MEDIA_ERR_NETWORK) {
    return 'A network error occurred while fetching the audio.'
  }
  if (code === MediaError.MEDIA_ERR_DECODE) {
    return 'An error occurred while decoding the audio.'
  }
  if (code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
    return 'The audio is missing or is in a format not supported by your browser.'
  }
  return 'An unknown error occurred.'
}

export default handleAudioLoadError
