import {useEffect, useRef} from 'react'
import {useSearchParams} from 'react-router-dom'
import {Clue} from '../domain/clue'

/**
 * Get a reference to a dialog element whose visibility is controlled by the `clue` URL search parameter.
 */
function useClueDialogRef(
  /**
   * Value of the `clue` URL search parameter to use for determining whether the dialog should be shown or not.
   */
  clue: Clue,
  onClose?: (clue: Clue) => void,
): React.RefObject<HTMLDialogElement> {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const clueParam = searchParams.get('clue')
  const showDialog = clue === clueParam

  // Open or close the dialog when the current clue changes.
  useEffect(() => {
    const dialogEl = dialogRef.current
    if (showDialog) {
      dialogEl?.showModal()
    } else {
      dialogEl?.close('clueChange')
    }
  }, [showDialog])

  // Remove the clue from the URL when the dialog is closed.
  useEffect(() => {
    function handleClose() {
      if (dialogRef.current?.returnValue === 'clueChange') {
        // The clue was changed in the URL, so don't remove the search parameter.
        onClose?.(clue)
        return
      }
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params)
          if (params.get('clue') === clue) {
            newParams.delete('clue')
          }
          return newParams
        },
        {replace: true},
      )
      onClose?.(clue)
    }
    const dialogEl = dialogRef.current
    dialogEl?.addEventListener('close', handleClose, {once: true})
    return () => {
      dialogEl?.removeEventListener('close', handleClose)
    }
  }, [clue, dialogRef, setSearchParams, onClose])

  return dialogRef
}

export default useClueDialogRef
