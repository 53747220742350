type Props = Readonly<{
  children: React.ReactNode
}>

function Letter({children}: Props): React.JSX.Element {
  return (
    <Container>
      <div
        className="mt-8 flex-grow space-y-3 overflow-y-auto px-4 pr-6 font-letter text-xs lg:text-base xl:mt-12 xl:px-6 xl:pr-8 xl:text-lg"
        lang="en-US"
      >
        {children}
      </div>
    </Container>
  )
}

function Container({
  children,
}: Readonly<{children: React.ReactNode}>): React.JSX.Element {
  return (
    <div className="flex h-full flex-col border-t border-transparent bg-[url(/letter.svg)] bg-cover bg-no-repeat pt-px">
      {children}
    </div>
  )
}

export default Letter
