type Props = React.ComponentProps<'svg'>

function CheckIcon(props: Props): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 30 30"
      {...props}
    >
      <g filter="url(#filter0_d_130_21)">
        <path
          fill="currentColor"
          d="m12.67 17.92-7.05-5.58L2 17.16 13.53 28 28 5.27 22.95 2 12.67 17.92Z"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_130_21"
          width="26"
          height="28"
          x="2"
          y="2"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_130_21"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_130_21"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default CheckIcon
