import clsx from 'clsx'
import {useState} from 'react'
import {To, useNavigate, useSearchParams} from 'react-router-dom'
import {Clue} from '../domain/clue'
import type Level from '../domain/level'
import shuffleOut from '../sounds/shuffleOut'
import CheckAnswerButton from './CheckAnswerButton'
import CloseIcon from './CloseIcon'
import ClueList from './ClueList'
import ClueListItem from './ClueListItem'
import {useInvestigatedClueCount} from './ClueStateProvider'
import CluesTagged from './CluesTagged'
import Container from './Container'
import IconButton from './IconButton'
import Letter from './Letter'
import LetterTutorialDialog from './LetterTutorialDialog'
import {useLevelState} from './LevelStateProvider'

type Props = Readonly<{
  level: Level
  title: string
  levelPathname: string
  clues: ReadonlyArray<Clue>
  correctAnswerText: string
  children: React.ReactNode
  onAnswer: () => void
}>

function LetterPage({
  level,
  levelPathname,
  title,
  clues,
  correctAnswerText,
  children,
  onAnswer,
}: Props): React.JSX.Element {
  const navigate = useNavigate()
  const levelState = useLevelState(level)
  const levelComplete = levelState === 'COMPLETE'
  const [searchParams] = useSearchParams()
  const [tutorialShown] = useState(
    () => searchParams.has('tutorial') && !levelComplete,
  )
  const investigatedCount = useInvestigatedClueCount()

  return (
    <>
      <h1 className="sr-only">{title}</h1>
      <div className="absolute left-0 top-0 flex h-full w-full gap-1">
        <section className="relative flex h-full flex-grow flex-col items-center">
          <div
            className={clsx(
              'z-10 my-2 ml-[72px] flex justify-end gap-4 self-stretch px-2',
              levelComplete && 'invisible',
            )}
          >
            {investigatedCount > 0 && (
              <div className="hidden flex-grow lg:justify-center xs:flex">
                <Container className="flex-grow lg:flex-grow-0">
                  <div className="flex h-full items-center justify-center p-3 text-center text-xs font-bold">
                    Tag the correct clues in the list
                  </div>
                </Container>
              </div>
            )}
            <div className="self-start">
              <IconButton
                icon={
                  <CloseIcon
                    className="h-6 w-6 tall:h-[30px] tall:w-[30px]"
                    aria-hidden
                  />
                }
                label="Close Letter"
                shape="square"
                onClick={() => {
                  const to: To = {
                    pathname: levelPathname,
                  }
                  if (tutorialShown) {
                    to.search = '?tutorial'
                  }
                  navigate(to)
                  shuffleOut.play()
                }}
              />
            </div>
          </div>
          <div className="-mt-8 h-full overflow-y-auto lg:mt-0">
            <Letter>{children}</Letter>
          </div>
        </section>
        <section
          className={clsx(
            'flex h-full min-w-48 flex-shrink-0 flex-col self-start p-2',
            levelComplete && 'invisible',
          )}
        >
          <div className="flex-shrink-0">
            <CluesTagged />
          </div>
          <div className="flex flex-col overflow-y-hidden">
            {investigatedCount === 0 ? (
              <div className="mt-2 inline-flex rounded-xl bg-[#d9d9d9]/50 p-2">
                <Container className="w-min px-5 py-16 text-center">
                  <div className="text-balance text-sm font-semibold">
                    Explore the scene to collect clues.
                  </div>
                </Container>
              </div>
            ) : (
              <ClueList>
                {clues.map((clue) => (
                  <ClueListItem key={clue} clue={clue} />
                ))}
              </ClueList>
            )}
          </div>
          {investigatedCount > 0 && (
            <div className="h-14 flex-shrink-0 tall:h-16">{/* Spacer */}</div>
          )}
        </section>
      </div>
      {investigatedCount > 0 && (
        <div className="absolute bottom-0 right-0 m-2">
          <CheckAnswerButton
            level={level}
            correctAnswerText={correctAnswerText}
            onCorrectAnswerClose={onAnswer}
            onIncorrectAnswerClose={onAnswer}
          />
        </div>
      )}
      <LetterTutorialDialog level={level} />
    </>
  )
}

export default LetterPage
