type Props = Readonly<{
  children: React.ReactNode
}>

function ClueList({children}: Props): React.JSX.Element {
  return (
    <ul className="mt-2 space-y-1.5 overflow-y-auto rounded-xl bg-[#d9d9d9]/50 px-1 py-2">
      {children}
    </ul>
  )
}

export default ClueList
