import {Helmet} from 'react-helmet-async'
import useRevealedClues from '../hooks/useRevealedClues'
import IndecipherableText from './IndecipherableText'
import LetterPage from './LetterPage'

function BriefingRoomLetterPage(): React.JSX.Element {
  const [revealedClues, revealClues] = useRevealedClues()
  return (
    <>
      <Helmet>
        <title>Briefing Room Letter</title>
      </Helmet>
      <LetterPage
        title="Briefing Room Letter"
        level="briefing-room"
        levelPathname="/briefing-room"
        clues={[
          'decoy-radio',
          'mysterious-woman',
          'pas-de-calais',
          'pair-of-wirecutters',
          'pays-de-la-loire',
          'blanche',
        ]}
        correctAnswerText={
          'On the back of the letter, you find the words "CHECK FILE" in your great-grandfather\'s handwriting.'
        }
        onAnswer={revealClues}
      >
        <p>Dear Rich,</p>
        <p>
          I arrived at the Garrison a month before the Big Show. Everyone moved
          with such purpose through the organized chaos of that enormous tent
          city! I got so turned around by it all that I stumbled into a
          top-secret communications tent. Before the MPs booted me out, I saw a
          mission that would go down in history.
        </p>
        <p>
          Back then I was ignorant enough to think that we must be in dire
          straits if we were sending a woman to the frontline, with nothing more
          than a{' '}
          <IndecipherableText
            revealed={revealedClues.includes('pair-of-wirecutters')}
          >
            pair of wirecutters
          </IndecipherableText>
          . Only later did I learn that this was Sonia &quot;
          <IndecipherableText revealed={revealedClues.includes('blanche')}>
            Blanche
          </IndecipherableText>
          &quot; d&apos;Artois. A week before we hit the beaches, she parachuted
          into the{' '}
          <IndecipherableText
            revealed={revealedClues.includes('pays-de-la-loire')}
          >
            Pays de la Loire
          </IndecipherableText>{' '}
          region of northwestern France. If it wasn&apos;t for her gathering
          intelligence, coordinating French resistance and sabotaging the Nazis
          behind their lines, you and I might not have made it through that day.
        </p>
        <p>
          Your Pal,
          <br />
          Frank &quot;The Yank&quot;
        </p>
      </LetterPage>
    </>
  )
}

export default BriefingRoomLetterPage
