import {Howl} from 'howler'
import handleAudioLoadError from '../utils/handleAudioLoadError'
import handleAudioPlayError from '../utils/handleAudioPlayError'

const howl = new Howl({
  src: ['/sounds/click.webm', '/sounds/click.mp3'],
  preload: true,
  onloaderror: handleAudioLoadError,
  onplayerror: handleAudioPlayError,
})

export default howl
