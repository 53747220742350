import {ElementType, HTMLAttributes} from 'react'

type Props = HTMLAttributes<HTMLOrSVGElement> &
  Readonly<{
    tag?: ElementType
  }>

function Container({tag: Tag = 'div', className, ...otherProps}: Props) {
  return (
    <Tag
      className={`${className == null ? '' : `${className} `}rounded-lg flex-grow border-2 border-button-border bg-container-fill shadow-container`}
      {...otherProps}
    />
  )
}

export default Container
