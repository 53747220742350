import Letter from './Letter'

function EpilogueLetter(): React.JSX.Element {
  return (
    <Letter>
      <p>Dear Rich,</p>
      <p>
        Looks like my time is almost up. But as you cricket-loving Brits would
        say, I&apos;ve had a good innings. If anything, I&apos;m looking forward
        to meeting my old buddies up there.
      </p>
      <p>
        But my one regret is that I never managed to send you these letters. I
        wish I had found out how to reach you before parting, but asking for
        someone&apos;s address isn&apos;t top of mind when you&apos;ve got
        Hitler&apos;s Zipper firing bullets past your head.
      </p>
      <p>
        Wherever your life took you, and whatever you achieved, I hope you know
        that you had a friend across the Atlantic wishing you the best every
        day. I&apos;ll see you up there, my friend.
      </p>
      <p>
        Forever your pal,
        <br />
        Frank &quot;The Yank&quot;
      </p>
    </Letter>
  )
}

export default EpilogueLetter
