import {Outlet} from 'react-router-dom'
import {FieldClue} from '../domain/clue'
import type ClueState from '../domain/clueState'
import useLoopingSound from '../hooks/useLoopingSound'
import ClueStateProvider from './ClueStateProvider'
import Scene from './Scene'

const AMBIENCE_SRC = [
  '/sounds/field-ambience.webm',
  '/sounds/field-ambience.mp3',
]
const MUSIC_SRC = ['/sounds/level-music.webm', '/sounds/level-music.mp3']

const INITIAL_CLUE_STATES: Readonly<Record<FieldClue, ClueState>> = {
  'jasper-maskelyne': 'NOT_INVESTIGATED',
  'camouflage-engineers': 'NOT_INVESTIGATED',
  'william-blass': 'NOT_INVESTIGATED',
  'first-us-army-group': 'NOT_INVESTIGATED',
  'operation-fortitude': 'NOT_INVESTIGATED',
  'supply-crates': 'NOT_INVESTIGATED',
  'inflatable-props': 'NOT_INVESTIGATED',
}

const CORRECT_CLUES: ReadonlyArray<FieldClue> = [
  'inflatable-props',
  'first-us-army-group',
  'operation-fortitude',
  'william-blass',
]

function FieldLayout(): React.JSX.Element {
  useLoopingSound(AMBIENCE_SRC)
  useLoopingSound(MUSIC_SRC)

  return (
    <main className="flex h-full items-center justify-center">
      <Scene
        backgroundSrc="/field.webp"
        backgroundSrcSet="/field-640w.webp 640w, /field-1280w.webp 1280w, /field-2560w.webp 2560w, /field.webp 3560w"
        backgroundAlt="An American army camp on an open field, dominated by tents and supply crates. A desk and uniformed White American male take up the foreground. Behind them are another male figure and what appears to be an M4 Sherman tank."
        width={1920}
        height={1080}
      >
        <ClueStateProvider
          level="field"
          initialClueStates={INITIAL_CLUE_STATES}
          correctClues={CORRECT_CLUES}
        >
          <Outlet />
        </ClueStateProvider>
      </Scene>
    </main>
  )
}

export default FieldLayout
