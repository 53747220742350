import {CLUE_NAMES, Clue} from '../domain/clue'
import toggleOff from '../sounds/toggleOff'
import toggleOn from '../sounds/toggleOn'
import {
  useClueActions,
  useClueState,
  useCorrectClueCount,
  useTaggedClueCount,
} from './ClueStateProvider'
import CrosshairIcon from './CrosshairIcon'

type Props = Readonly<{
  clue: Clue
}>

function ClueListItem({clue}: Props): React.JSX.Element {
  const clueState = useClueState(clue)
  if (clueState === 'INVESTIGATED') {
    return <InvestigatedClueListItem clue={clue} />
  }
  if (clueState === 'TAGGED') {
    return <TaggedClueListItem clue={clue} />
  }
  if (clueState === 'CHECKED_CORRECT') {
    return <CheckedCorrectClueListItem clue={clue} />
  }
  if (clueState === 'CHECKED_INCORRECT') {
    return <CheckedIncorrectClueListItem clue={clue} />
  }
  return <HiddenClueListItem />
}

function HiddenClueListItem(): React.JSX.Element {
  return (
    <li className="leading-none">
      <ClueListItemContainer>
        <span className="absolute h-6 w-6 -translate-x-px rounded-2xl border border-button-border bg-primary-1 p-1">
          <HiddenIcon className="h-full w-full" aria-label="Not Investigated" />
        </span>
        <span className="pl-7 pr-3">(Clue Hidden)</span>
      </ClueListItemContainer>
    </li>
  )
}

function HiddenIcon(props: React.ComponentProps<'svg'>): React.JSX.Element {
  return (
    <svg
      fill="none"
      viewBox="0 0 11 15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M1.0607 6.6682c-.1374-.32-.2389-.6283-.3046-.925a4.6316 4.6316 0 0 1-.0896-.8989c0-.8668.218-1.6232.654-2.269.442-.6457 1.0454-1.146 1.8099-1.501C3.895.7138 4.767.5334 5.7466.5334c1.0393 0 1.9353.1862 2.6879.5585.7586.3724 1.341.8785 1.7471 1.5185.4122.64.6182 1.3613.6182 2.1642 0 .5643-.0925 1.0501-.2777 1.4573-.1792.4073-.4151.7564-.7078 1.0473a6.2221 6.2221 0 0 1-.9139.7679c-.3225.2153-.6302.4189-.9228.6109-.2867.1861-.5227.381-.7078.5847-.1792.2036-.2688.4363-.2688.698v.3753H4.2324v-.4188c0-.512.0866-.9454.2599-1.3003.1792-.3549.4061-.6632.6809-.925a5.7459 5.7459 0 0 1 .878-.6894c.3106-.2037.6033-.4073.878-.611.2808-.2036.5078-.4275.681-.6719.1792-.2501.2688-.5468.2688-.8901 0-.3258-.0836-.6254-.2509-.8988-.1672-.2735-.4121-.4916-.7347-.6545-.3165-.1688-.7048-.2531-1.1647-.2531-.4241 0-.8034.0756-1.1379.2269-.3345.1454-.6003.3578-.7974.637-.1911.2793-.2867.6109-.2867.9949 0 .1396.015.2792.0448.4188.0358.1397.0866.288.1523.4451l-2.643.9425Zm2.9746 5.5599h3.1538v2.7052H4.0353v-2.7052Z"
      />
    </svg>
  )
}

function InvestigatedClueListItem({clue}: Props): React.JSX.Element {
  const taggedCount = useTaggedClueCount()
  const correctCount = useCorrectClueCount()
  const maxTagged = taggedCount >= correctCount
  const {tagOrUntagClue} = useClueActions()
  return (
    <li className="leading-none">
      <button
        className="w-full rounded-2xl"
        aria-disabled={maxTagged}
        onClick={() => {
          if (maxTagged) {
            return
          }
          tagOrUntagClue(clue, true)
          toggleOn.play()
        }}
      >
        <ClueListItemContainer>
          <span className="absolute h-6 w-6 -translate-x-px rounded-2xl border border-button-border bg-secondary-3 bg-striped p-1">
            <InvestigatedIcon
              className="h-full w-full"
              aria-label="Investigated"
            />
          </span>
          <span className="pl-7 pr-3">{CLUE_NAMES[clue]}</span>
        </ClueListItemContainer>
      </button>
    </li>
  )
}

function InvestigatedIcon(
  props: React.ComponentProps<'svg'>,
): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5716 0.533203C7.9956 0.533203 5.90918 2.61963 5.90918 5.1956C5.90918 7.77157 7.9956 9.858 10.5716 9.858C13.1476 9.858 15.234 7.77157 15.234 5.1956C15.234 2.61963 13.1476 0.533203 10.5716 0.533203ZM10.5716 8.1096C8.96305 8.1096 7.65758 6.80413 7.65758 5.1956C7.65758 3.58707 8.96305 2.2816 10.5716 2.2816C12.1801 2.2816 13.4856 3.58707 13.4856 5.1956C13.4856 6.80413 12.1801 8.1096 10.5716 8.1096Z"
        fill="currentColor"
      />
      <path
        d="M6.80443 6.6525L0.533203 12.7681L2.64479 14.9334L8.91602 8.81784L6.80443 6.6525Z"
        fill="currentColor"
      />
    </svg>
  )
}

function TaggedClueListItem({clue}: Props): React.JSX.Element {
  const {tagOrUntagClue} = useClueActions()
  return (
    <li className="leading-none">
      <button
        className="w-full rounded-2xl"
        onClick={() => {
          tagOrUntagClue(clue, false)
          toggleOff.play()
        }}
      >
        <ClueListItemContainer highlighted>
          <span className="absolute h-6 w-6 -translate-x-px rounded-2xl border border-button-border bg-secondary-3 p-1">
            <CrosshairIcon className="h-full w-full" aria-label="Tagged" />
          </span>
          <span className="pl-7 pr-3">{CLUE_NAMES[clue]}</span>
        </ClueListItemContainer>
      </button>
    </li>
  )
}

function CheckedCorrectClueListItem({clue}: Props): React.JSX.Element {
  return (
    <li className="leading-none">
      <ClueListItemContainer highlighted aria-label="Correct">
        <span className="absolute h-6 w-6 -translate-x-px rounded-2xl border border-button-border bg-[#A9DBA1] p-1">
          <CorrectIcon className="h-full w-full" aria-label="Correct" />
        </span>
        <span className="pl-7 pr-3">{CLUE_NAMES[clue]}</span>
      </ClueListItemContainer>
    </li>
  )
}

function CorrectIcon(props: React.ComponentProps<'svg'>): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.75707 9.55732L2.99707 6.58132L1.06641 9.15198L7.21574 14.9333L14.9331 2.81065L12.2397 1.06665L6.75707 9.55732Z"
        fill="currentColor"
      />
    </svg>
  )
}

function CheckedIncorrectClueListItem({clue}: Props): React.JSX.Element {
  return (
    <li className="leading-none">
      <ClueListItemContainer>
        <span className="absolute h-6 w-6 -translate-x-px rounded-2xl border border-button-border bg-[#E0928C] p-1">
          <IncorrectIcon className="h-full w-full" aria-label="Incorrect" />
        </span>
        <span className="pl-7 pr-3">{CLUE_NAMES[clue]}</span>
      </ClueListItemContainer>
    </li>
  )
}

function IncorrectIcon(props: React.ComponentProps<'svg'>): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.2611 1.08798L8.01574 5.33332L3.75441 1.06665L1.06641 3.75465L5.33307 8.01598L1.08241 12.2667L3.74374 14.928L7.99441 10.6773L12.2451 14.9333L14.9331 12.2453L10.6771 7.99465L14.9224 3.74932L12.2611 1.08798Z"
        fill="currentColor"
      />
    </svg>
  )
}

function ClueListItemContainer({
  highlighted = false,
  children,
}: Readonly<{
  highlighted?: boolean
  children: React.ReactNode
}>): React.JSX.Element {
  return (
    <div
      className={`${highlighted ? 'bg-secondary-3' : 'bg-container-fill'} relative flex min-h-6 items-center gap-1 rounded-2xl border border-button-border text-xs font-semibold shadow-container`}
    >
      {children}
    </div>
  )
}

export default ClueListItem
