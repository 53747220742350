import {
  Menu as HeadlessMenu,
  MenuButton as HeadlessMenuButton,
  MenuItems as HeadlessMenuItems,
  MenuItem,
  MenuSeparator,
} from '@headlessui/react'
import {forwardRef} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import useFullscreen, {SUPPORTS_FULLSCREEN} from '../hooks/useFullscreen'
import AlertIcon from './AlertIcon'
import Container from './Container'
import FlatIcon from './FlatIcon'
import FullscreenIcon from './FullscreenIcon'
import MenuButton from './MenuButton'
import {useMuted} from './SoundProvider'

type Props = Readonly<{
  onFeedback?: () => void
  onShare?: () => void
}>

function Menu({onFeedback, onShare}: Props): React.JSX.Element {
  const [fullscreen, toggleFullscreen] = useFullscreen()
  const location = useLocation()
  const navigate = useNavigate()
  const [muted, setMuted] = useMuted()
  return (
    <HeadlessMenu>
      <HeadlessMenuButton id="menu-button" as={MenuButton} />
      <HeadlessMenuItems as={MenuItems} portal={false}>
        {location.pathname !== '/' && location.pathname !== '/attic' ? (
          <>
            <MenuItem>
              <MenuItemButton
                onClick={() => {
                  navigate('/attic')
                }}
              >
                <div className="flex items-center gap-2">
                  <FlatIcon icon={<HomeIcon />} />
                  Home
                </div>
              </MenuItemButton>
            </MenuItem>
            <MenuSeparator className="mx-2 h-px bg-tertiary-3/20" />
          </>
        ) : null}
        <MenuItem>
          <MenuItemButton
            onClick={(ev) => {
              ev.preventDefault()
              setMuted(!muted)
            }}
          >
            <div className="flex items-center gap-2">
              <FlatIcon
                icon={
                  muted ? (
                    <SoundOffIcon className="h-[22px] w-[22px]" aria-hidden />
                  ) : (
                    <SoundOnIcon className="h-[22px] w-[22px]" aria-hidden />
                  )
                }
              />
              {muted ? 'Sound On' : 'Sound Off'}
            </div>
          </MenuItemButton>
        </MenuItem>
        {SUPPORTS_FULLSCREEN && (
          <>
            <MenuSeparator className="mx-2 h-px bg-tertiary-3/20" />
            <MenuItem>
              <MenuItemButton onClick={toggleFullscreen}>
                <div className="flex items-center gap-2">
                  <FlatIcon icon={<FullscreenIcon aria-hidden />} />
                  {fullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
                </div>
              </MenuItemButton>
            </MenuItem>
          </>
        )}
        {onShare != null && (
          <>
            <MenuSeparator className="mx-2 h-px bg-tertiary-3/20" />
            <MenuItem>
              <MenuItemButton onClick={onShare}>
                <div className="flex items-center gap-2">
                  <FlatIcon icon={<ShareIcon aria-hidden />} />
                  Share experience
                </div>
              </MenuItemButton>
            </MenuItem>
          </>
        )}
        {onFeedback != null && (
          <>
            <MenuSeparator className="mx-2 h-px bg-tertiary-3/20" />
            <MenuItem>
              <MenuItemButton onClick={onFeedback}>
                <div className="flex items-center gap-2">
                  <FlatIcon
                    icon={<AlertIcon className="h-full w-full" aria-hidden />}
                    variant="alert"
                  />
                  Report a bug
                </div>
              </MenuItemButton>
            </MenuItem>
          </>
        )}
        <MenuSeparator className="mx-2 h-px bg-tertiary-3/20" />
      </HeadlessMenuItems>
    </HeadlessMenu>
  )
}

const MenuItems = forwardRef<HTMLDivElement, {children: React.ReactNode}>(
  function MenuItems({children, ...props}, ref): React.JSX.Element {
    return (
      <div
        ref={ref}
        className="relative mt-2 rounded-lg text-xs font-semibold"
        {...props}
      >
        <Container>{children}</Container>
      </div>
    )
  },
)

function ShareIcon(props: React.ComponentProps<'svg'>): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1100_3711)">
        <path
          d="M7.50165 11.5356L10.4985 11.5356L10.4985 2.53564L7.50165 2.53564L7.50165 11.5356Z"
          fill="currentColor"
        />
        <path
          d="M5.25391 4.76758L12.7461 4.76758L9.02039 -0.000200227L5.25391 4.76758Z"
          fill="#333333"
        />
        <path d="M6 8H3V16H15V8H12" stroke="#333333" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_1100_3711">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

function SoundOnIcon(props: React.ComponentProps<'svg'>): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.6416 3.41345L13.3853 5.66301L11.1223 3.40002L9.69202 4.83034L11.9617 7.09332L9.70545 9.34959L11.1156 10.7598L13.3719 8.50349L15.6281 10.7665L17.0585 9.33616L14.7955 7.07989L17.0517 4.82362L15.6416 3.41345Z"
        fill="currentColor"
      />
      <path
        d="M6.73798 2.19995L3.32998 4.92995H0.599976V9.02195H3.32998L6.73798 11.752V2.19995Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

function SoundOffIcon(props: React.ComponentProps<'svg'>): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.73798 2.19995L3.32998 4.92995H0.599976V9.02195H3.32998L6.73798 11.752V2.19995Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M11.634 4.59998C12.276 5.24198 12.63 6.10598 12.63 7.01198C12.63 7.91798 12.276 8.78198 11.634 9.42398"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M14.04 2.19995C15.318 3.47795 16.038 5.21195 16.038 7.01795C16.038 8.82395 15.318 10.558 14.04 11.836"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function HomeIcon(props: React.ComponentProps<'svg'>): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99626 0L0.200012 6.53343V16.8H15.8V6.53343L7.99626 0ZM5.52245 8.98094H10.3351V15.2362H5.52245V8.98094Z"
        fill="currentColor"
      />
    </svg>
  )
}

const MenuItemButton = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<'button'>
>(function MenuItemButton(props, ref): React.JSX.Element {
  return (
    <button
      ref={ref}
      className="block w-full p-2 first:rounded-t-lg last:rounded-b-lg data-[focus]:bg-button-fill-hover"
      {...props}
    />
  )
})

export default Menu
