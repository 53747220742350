import {useEffect, useRef} from 'react'
import {Helmet} from 'react-helmet-async'
import {useNavigate} from 'react-router-dom'
import HelperText from './HelperText'
import InfoDialog from './InfoDialog'
import ItemButton from './ItemButton'

function EpiloguePage(): React.JSX.Element {
  const navigate = useNavigate()
  const dialogRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    dialogRef.current?.showModal()
  }, [])

  return (
    <>
      <Helmet>
        <title>Epilogue</title>
      </Helmet>
      <div className="absolute bottom-0 w-full text-center">
        <HelperText>Tap on the telephone to make the phone call.</HelperText>
      </div>
      <div className="absolute left-[66.75%] top-[48%] -translate-x-1/2 -translate-y-1/2">
        <ItemButton
          name="Telephone"
          pointerPosition="bottom-right"
          onClick={() => {
            navigate('/epilogue/phone-call')
            gtag('event', 'start_phone_call')
          }}
        />
      </div>
      <InfoDialog ref={dialogRef} imageUrl="/clues.webp">
        <p>
          I think I&apos;ve found enough clues about Grandpa Frank&apos;s
          friend.
        </p>
        <p>Richard York. Walthamstow. And a phone number.</p>
        <p>
          It took a lot of sleuthing, but I&apos;m confident enough to make a
          call ...
        </p>
      </InfoDialog>
    </>
  )
}

export default EpiloguePage
