import React from 'react'
import {Helmet} from 'react-helmet-async'
import IntroLetter from './IntroLetter'
import {LetterPageLayout} from './LetterPageLayout'

function IntroLetterPage(): React.JSX.Element {
  return (
    <>
      <Helmet>
        <title>Intro Letter</title>
      </Helmet>
      <LetterPageLayout onNextUrl="/attic?tutorial">
        <IntroLetter />
      </LetterPageLayout>
    </>
  )
}

export default IntroLetterPage
