type Props = React.ComponentProps<'svg'>

function Pointer(props: Props): React.JSX.Element {
  return (
    <svg
      width="43"
      height="47"
      viewBox="0 0 43 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_531_1035)">
        <path
          d="M7.12741 12.2553C5.6008 10.0304 4.09043 7.80541 2.56382 5.5967C1.68683 4.3137 1.84923 2.65716 2.93735 1.69897C4.09043 0.708295 5.71448 0.773258 6.83508 1.87761C6.99748 2.04002 7.15989 2.23491 7.28981 2.42979C8.86515 4.71971 10.4405 7.00963 11.9996 9.31578C12.1295 9.51067 12.1782 9.57563 12.3244 9.31578C13.3963 7.3182 15.9785 7.30196 17.3265 9.26706C17.5051 9.54315 17.7 9.803 17.8949 10.0791C18.0735 9.85172 18.2522 9.62435 18.4308 9.39699C19.6489 7.9191 21.9225 8.11398 23.0756 9.803C23.238 10.0304 23.2867 10.0953 23.4492 9.803C24.4885 7.85413 27.087 7.83789 28.4025 9.75428C30.0428 12.1416 31.6669 14.5452 33.3234 16.9164C33.7294 17.501 34.0055 18.1182 34.1842 18.8165C34.8987 21.6911 35.6296 24.5657 36.3604 27.4402C36.4578 27.7975 36.3604 28.0736 36.0843 28.2685C32.8687 30.4772 29.653 32.6697 26.4212 34.8784C26.0964 35.1058 25.7878 35.0733 25.463 34.8134C24.5697 34.1151 23.6928 33.4005 22.767 32.7509C22.3935 32.4748 21.9388 32.3286 21.5165 32.1337C19.0967 31.0781 16.6444 30.055 14.257 28.9344C13.5424 28.6095 12.9415 28.0086 12.3081 27.5052C10.2131 25.8811 8.11808 24.2571 6.02305 22.633C5.35719 22.1133 5.06486 21.4475 5.19478 20.6192C5.43839 19.1088 7.01372 17.6472 8.44289 17.6959C8.91387 17.7121 9.40108 17.8745 9.85582 18.0857C10.4892 18.378 11.0739 18.7678 11.6748 19.1088C11.7235 19.1413 11.8047 19.1413 11.8534 19.1576C11.8209 19.1088 11.8047 19.0439 11.7722 18.9951C10.2294 16.7377 8.6865 14.4965 7.14365 12.2391L7.12741 12.2553Z"
          fill="#F2EEEB"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M38.8703 28.5933L25.5386 37.7251L28.6682 42.2941L41.9999 33.1622L38.8703 28.5933Z"
          fill="#F2EEEB"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_531_1035"
          x="0.000610352"
          y="0"
          width="42.9994"
          height="46.2939"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_531_1035"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_531_1035"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default Pointer
