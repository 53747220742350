import click from '../sounds/click'
import InvestigatedClueIcon from './InvestigatedClueIcon'

type Props = Readonly<{
  /**
   * Accessible label to describe what the button does in a non-visual way.
   */
  label: string
}> &
  React.ComponentProps<'button'>

function InvestigatedClueButton({
  label,
  onClick,
  ...props
}: Props): React.JSX.Element {
  return (
    <button
      className="rounded-full border border-button-border bg-clue-investigated-fill bg-striped p-2 text-tertiary-3 shadow-clue-investigated hover:bg-clue-investigated-fill-hover hover:bg-striped-hover hover:shadow-clue-investigated-hover active:translate-y-px active:bg-striped-hover active:shadow-clue-investigated-active lg:border-2 lg:p-3 lg:shadow-clue-investigated-lg lg:hover:shadow-clue-investigated-lg-hover lg:active:translate-y-0.5 lg:active:shadow-clue-investigated-lg-active"
      onClick={(ev) => {
        click.play()
        onClick?.(ev)
      }}
      {...props}
    >
      <InvestigatedClueIcon
        className="h-6 w-6 lg:h-[30px] lg:w-[30px]"
        aria-hidden
      />
      <span className="sr-only">{label}</span>
    </button>
  )
}

export default InvestigatedClueButton
