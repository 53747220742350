import clsx from 'clsx'
import {forwardRef} from 'react'
import click from '../sounds/click'

type Props = Readonly<{
  /**
   * Icon element to render.
   */
  icon: React.JSX.Element
  /**
   * Accessible label to describe what the button does in a non-visual way.
   */
  label: string
  /**
   * Shape of the button.
   */
  shape?: 'round' | 'square'
  /**
   * Visual variant of the button.
   */
  variant?: 'primary' | 'secondary'
}> &
  React.ComponentProps<'button'>

const IconButton = forwardRef<HTMLButtonElement, Props>(function IconButton(
  {
    icon,
    label,
    shape = 'round',
    variant = 'primary',
    disabled,
    onClick,
    ...props
  },
  ref,
): React.JSX.Element {
  return (
    <button
      ref={ref}
      className={clsx(
        'aria-disabled:pacity-50 rounded-full border border-button-border p-2 text-tertiary-3 active:translate-y-0.5 aria-disabled:!translate-y-0 aria-disabled:opacity-50 lg:border-2 tall:p-3',
        shape === 'round' && 'rounded-full',
        shape === 'square' && 'rounded-xl',
        variant === 'primary' &&
          'bg-button-fill shadow-button-lg hover:bg-button-fill-hover hover:shadow-button-lg-hover active:bg-button-fill-active active:shadow-button-lg-active aria-disabled:shadow-button-lg',
        variant === 'secondary' &&
          'bg-button-fill-secondary shadow-button-secondary-lg hover:bg-button-fill-secondary-hover hover:shadow-button-secondary-lg-hover active:bg-button-fill-secondary-active active:shadow-button-secondary-lg-active aria-disabled:shadow-button-secondary-lg',
      )}
      onClick={(ev) => {
        if (disabled) {
          return
        }
        click.play()
        onClick?.(ev)
      }}
      aria-disabled={disabled}
      {...props}
    >
      {icon}
      <span className="sr-only">{label}</span>
    </button>
  )
})

export default IconButton
