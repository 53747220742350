import * as Sentry from '@sentry/react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {RouterProvider, createBrowserRouter} from 'react-router-dom'
import useLoopingSound from '../hooks/useLoopingSound'
import BriefingRoomLayout from './BriefingRoomLayout'
import BriefingRoomLetterPage from './BriefingRoomLetterPage'
import BriefingRoomPage from './BriefingRoomPage'
import EpilogueLetterPage from './EpilogueLetterPage'
import EpiloguePage from './EpiloguePage'
import FieldLayout from './FieldLayout'
import FieldLetterPage from './FieldLetterPage'
import FieldPage from './FieldPage'
import ForceLandscape from './ForceLandscape'
import HubPage from './HubPage'
import IntroLetterPage from './IntroLetterPage'
import LevelStateProvider from './LevelStateProvider'
import NotFoundPage from './NotFoundPage'
import OfficeLayout from './OfficeLayout'
import OfficeLetterPage from './OfficeLetterPage'
import OfficePage from './OfficePage'
import PhoneCallPage from './PhoneCallPage'
import Scene from './Scene'
import SoundProvider from './SoundProvider'
import StartPage from './StartPage'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: getAtticLayout(<StartPage />, true),
  },
  {
    path: '/attic',
    element: getAtticLayout(<HubPage />),
  },
  {
    path: '/attic/letter',
    element: getAtticLayout(<IntroLetterPage />),
  },
  {
    path: '/briefing-room',
    element: <BriefingRoomLayout />,
    children: [
      {
        index: true,
        element: <BriefingRoomPage />,
      },
      {
        path: 'letter',
        element: <BriefingRoomLetterPage />,
      },
    ],
  },
  {
    path: '/field',
    element: <FieldLayout />,
    children: [
      {
        index: true,
        element: <FieldPage />,
      },
      {
        path: 'letter',
        element: <FieldLetterPage />,
      },
    ],
  },
  {
    path: '/office',
    element: <OfficeLayout />,
    children: [
      {
        index: true,
        element: <OfficePage />,
      },
      {
        path: 'letter',
        element: <OfficeLetterPage />,
      },
    ],
  },
  {
    path: '/epilogue',
    element: getAtticLayout(<EpiloguePage />),
  },
  {
    path: '/epilogue/phone-call',
    element: getAtticLayout(<PhoneCallPage />),
  },
  {
    path: '/epilogue/letter',
    element: getAtticLayout(<EpilogueLetterPage />),
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
])

function App(): React.JSX.Element {
  return (
    <HelmetProvider>
      <SoundProvider>
        <LevelStateProvider>
          <ForceLandscape>
            <Helmet
              defaultTitle="Heroes of World War II Uncovered"
              titleTemplate="%s - Heroes of World War II Uncovered"
            />
            <RouterProvider router={router} />
          </ForceLandscape>
        </LevelStateProvider>
      </SoundProvider>
    </HelmetProvider>
  )
}

function getAtticLayout(children: React.ReactNode, backdrop = false) {
  return (
    <main
      className={`flex h-full items-center justify-center${backdrop ? ' bg-[#47434399]' : ''}`}
    >
      <Scene
        backgroundSrc="/attic.webp"
        backgroundSrcSet="/attic-640w.webp 640w, /attic-1280w.webp 1280w, /attic-2560w.webp 2560w, /attic.webp 3560w"
        backgroundAlt="You are inside your great-grandfather's attic, covered wall-to-wall with old boxes, files and notes. A large window looks out onto a clear day outside."
        width={3840}
        height={2160}
      >
        <AtticMusic>{children}</AtticMusic>
      </Scene>
    </main>
  )
}

const MUSIC_SRC = ['/sounds/attic-music.webm', '/sounds/attic-music.mp3']

function AtticMusic({children}: Readonly<{children: React.ReactNode}>) {
  useLoopingSound(MUSIC_SRC)
  return children
}

export default App
