import {Helmet} from 'react-helmet-async'
import useRevealedClues from '../hooks/useRevealedClues'
import IndecipherableText from './IndecipherableText'
import LetterPage from './LetterPage'

function FieldLetterPage(): React.JSX.Element {
  const [revealedClues, revealClues] = useRevealedClues()

  return (
    <>
      <Helmet>
        <title>Field Letter</title>
      </Helmet>
      <LetterPage
        level="field"
        title="Field Letter"
        levelPathname="/field"
        clues={[
          'jasper-maskelyne',
          'william-blass',
          'supply-crates',
          'first-us-army-group',
          'inflatable-props',
          'operation-fortitude',
          'camouflage-engineers',
        ]}
        correctAnswerText="You read on, and your great-grandfather reminds Rich about his promise to host him as a guest in London. Your eyes wander towards a board near the letter."
        onAnswer={revealClues}
      >
        <p>Dear Rich,</p>
        <p>
          Approaching the launch site in Portsmouth, our cockiness gave way to
          silent determination. At first, it was a relief to see a well-equipped
          Allied army up ahead. My CO told me that it was the{' '}
          <IndecipherableText
            revealed={revealedClues.includes('first-us-army-group')}
          >
            First U.S. Army Group
          </IndecipherableText>{' '}
          led by General Patton, no less. But when we got closer, we realized
          that their tanks and artillery pieces were{' '}
          <IndecipherableText
            revealed={revealedClues.includes('inflatable-props')}
          >
            inflatable props
          </IndecipherableText>
          !
        </p>
        <p>
          The entire army was fictional, part of the grand misdirection of{' '}
          <IndecipherableText
            revealed={revealedClues.includes('operation-fortitude')}
          >
            Operation Fortitude
          </IndecipherableText>
          , and the man in charge looked like he belonged on a Hollywood movie
          set, not a battlefield. This was Camouflage Engineer,{' '}
          <IndecipherableText
            revealed={revealedClues.includes('william-blass')}
          >
            William Blass
          </IndecipherableText>
          . No surprise that he later became a famous fashion designer.
        </p>
        <p>
          Already a good laugh at Hitler &apos;s expense, but if it wasn&apos;t
          for these kinds of ingenious diversions, our job would have been much
          tougher.
        </p>
        <p>
          Your Pal,
          <br />
          Frank &quot;The Yank&quot;
        </p>
      </LetterPage>
    </>
  )
}

export default FieldLetterPage
