import {useEffect, useRef} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useLevelsComplete} from '../components/LevelStateProvider'
import type Level from '../domain/level'

function useItemDialogRef(level: Level): React.RefObject<HTMLDialogElement> {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const showDialog =
    searchParams.has('item') && searchParams.get('completed') === level
  const levelsComplete = useLevelsComplete()

  // Open or close the dialog on navigation.
  useEffect(() => {
    const dialogEl = dialogRef.current
    if (showDialog) {
      dialogEl?.showModal()
    } else {
      dialogEl?.close('nav')
    }
  }, [showDialog])

  // Remove the search parameters when the dialog is closed.
  useEffect(() => {
    function handleClose() {
      if (dialogRef.current?.returnValue === 'nav') {
        // The dialog was closed by navigation so don't remove the search parameters.
        return
      }
      if (levelsComplete) {
        navigate('/epilogue/letter')
        gtag('event', 'start_epilogue')
      } else {
        setSearchParams(
          (params) => {
            const newParams = new URLSearchParams(params)
            newParams.delete('item')
            newParams.delete('completed')
            return newParams
          },
          {replace: true},
        )
      }
    }
    const dialogEl = dialogRef.current
    dialogEl?.addEventListener('close', handleClose, {once: true})
    return () => {
      dialogEl?.removeEventListener('close', handleClose)
    }
  }, [levelsComplete, navigate, setSearchParams])

  return dialogRef
}

export default useItemDialogRef
