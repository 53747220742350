import type {Howl} from 'howler'
import click from '../sounds/click'

type Props = React.ComponentProps<'button'> &
  Readonly<{
    sound?: Howl
    icon?: React.JSX.Element
  }>

function Button({
  icon,
  children,
  sound = click,
  onClick,
  ...props
}: Props): React.JSX.Element {
  return (
    <button
      className="flex items-center gap-2.5 rounded-xl border-2 border-button-border bg-button-fill px-2 py-3 text-base font-extrabold !leading-[normal] text-tertiary-3 shadow-button-lg hover:bg-button-fill-hover hover:shadow-button-lg-hover active:translate-y-0.5 active:bg-button-fill-active active:shadow-button-lg-active aria-disabled:!translate-y-0 aria-disabled:cursor-not-allowed aria-disabled:bg-button-fill-disabled aria-disabled:text-opacity-50 aria-disabled:shadow-button-disabled tall:px-4 tall:py-4 tall:text-lg"
      onClick={(ev) => {
        sound.play()
        onClick?.(ev)
      }}
      {...props}
    >
      {icon}
      {children}
    </button>
  )
}

export default Button
