import {forwardRef} from 'react'
import InfoDialog from './InfoDialog'

const IncorrectAnswerDialog = forwardRef<HTMLDialogElement>(
  function IncorrectAnswerDialog(_props, ref): React.JSX.Element {
    return (
      <InfoDialog
        ref={ref}
        imageUrl="/incorrect-answer.webp"
        buttonText="TRY AGAIN"
      >
        <p>Important parts of the story are still missing or incorrect.</p>
        <p>
          Check which parts of the letter are blurred out, and TAG the matching
          phrases in your clue list.
        </p>
      </InfoDialog>
    )
  },
)

export default IncorrectAnswerDialog
