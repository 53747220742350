import {useNavigate} from 'react-router-dom'
import Button from './Button'

type LetterPageLayoutProps = Readonly<{
  children: React.ReactNode
  onNextUrl: string
}>
export function LetterPageLayout({
  children,
  onNextUrl,
}: LetterPageLayoutProps): React.JSX.Element {
  const navigate = useNavigate()

  return (
    <div className="absolute left-0 top-0 ml-12 flex h-full items-end overflow-hidden tall:ml-16">
      <div className="animate__animated animate__fadeIn flex h-full items-end">
        <div className="ml-2 h-full">{children}</div>
        <div className="p-2">
          <Button
            onClick={() => {
              navigate(onNextUrl)
            }}
          >
            NEXT
          </Button>
        </div>
      </div>
    </div>
  )
}
