type Props = React.ComponentProps<'svg'>

function InvestigatedClueIcon(props: Props): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 30 30"
      {...props}
    >
      <g fill="currentColor" filter="url(#filter0_d_130_83)">
        <path d="M19.8222 1c-4.83 0-8.742 3.912-8.742 8.742 0 4.8299 3.912 8.742 8.742 8.742 4.8299 0 8.742-3.9121 8.742-8.742 0-4.83-3.9121-8.742-8.742-8.742Zm0 14.2057c-3.016 0-5.4638-2.4477-5.4638-5.4637s2.4478-5.4638 5.4638-5.4638 5.4637 2.4478 5.4637 5.4638-2.4477 5.4637-5.4637 5.4637Z" />
        <path d="M12.7586 12.4733 1 23.94 4.9593 28l11.7585-11.4667-3.9592-4.06Z" />
      </g>
      <defs>
        <filter
          id="filter0_d_130_83"
          width="27.5641"
          height="29"
          x="1"
          y="1"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_130_83"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_130_83"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default InvestigatedClueIcon
