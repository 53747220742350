import {useEffect, useRef} from 'react'
import {useSearchParams} from 'react-router-dom'
import type Level from '../domain/level'
import InfoDialog from './InfoDialog'
import {useLevelState} from './LevelStateProvider'

type Props = Readonly<{
  level: Level
}>

function LetterTutorialDialog({level}: Props): React.JSX.Element {
  const ref = useRef<HTMLDialogElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const levelState = useLevelState(level)
  const levelComplete = levelState === 'COMPLETE'
  const showTutorial = searchParams.has('tutorial') && !levelComplete

  useEffect(() => {
    if (showTutorial) {
      ref.current?.showModal()
    }
  }, [showTutorial])

  useEffect(() => {
    function handleClose() {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params)
          newParams.delete('tutorial')
          return newParams
        },
        {replace: true},
      )
    }
    const dialogEl = ref.current
    if (dialogEl == null) {
      return
    }
    dialogEl.addEventListener('close', handleClose)
    return () => {
      dialogEl.removeEventListener('close', handleClose)
    }
  }, [setSearchParams])

  return (
    <InfoDialog ref={ref} imageUrl="/letter-tutorial.webp">
      <p>
        Parts of your great-grandfather&apos;s letter are missing. To assemble
        the full text, you&apos;ll have to SEARCH FOR CLUES in the next scene.
      </p>
      <p>
        Press the &quot;X&quot; to close this letter and start the clue hunt.
      </p>
    </InfoDialog>
  )
}

export default LetterTutorialDialog
