import {Outlet} from 'react-router-dom'
import {BriefingRoomClue} from '../domain/clue'
import type ClueState from '../domain/clueState'
import useLoopingSound from '../hooks/useLoopingSound'
import ClueStateProvider from './ClueStateProvider'
import Scene from './Scene'

const INITIAL_CLUE_STATES: Readonly<Record<BriefingRoomClue, ClueState>> = {
  'decoy-radio': 'NOT_INVESTIGATED',
  'mysterious-woman': 'NOT_INVESTIGATED',
  'pas-de-calais': 'NOT_INVESTIGATED',
  'pair-of-wirecutters': 'NOT_INVESTIGATED',
  'pays-de-la-loire': 'NOT_INVESTIGATED',
  blanche: 'NOT_INVESTIGATED',
}

const CORRECT_CLUES: ReadonlyArray<BriefingRoomClue> = [
  'pair-of-wirecutters',
  'pays-de-la-loire',
  'blanche',
]

const AMBIENCE_SRC = [
  '/sounds/briefing-room-ambience.webm',
  '/sounds/briefing-room-ambience.mp3',
]

const MUSIC_SRC = ['/sounds/level-music.webm', '/sounds/level-music.mp3']

function BriefingRoomLayout(): React.JSX.Element {
  useLoopingSound(AMBIENCE_SRC)
  useLoopingSound(MUSIC_SRC)

  return (
    <main className="flex h-full items-center justify-center">
      <Scene
        backgroundSrc="/briefing-room.webp"
        backgroundSrcSet="/briefing-room-640w.webp 640w, /briefing-room-1280w.webp 1280w, /briefing-room-2560w.webp 2560w, /briefing-room.webp 3560w"
        backgroundAlt="This is the interior of a World War 2 communications tent. Radio equipment, operation maps and top-secret documents are strewn about. A uniformed White British female is present in the foreground."
        width={3840}
        height={2160}
      >
        <ClueStateProvider
          level="briefing-room"
          initialClueStates={INITIAL_CLUE_STATES}
          correctClues={CORRECT_CLUES}
        >
          <Outlet />
        </ClueStateProvider>
      </Scene>
    </main>
  )
}

export default BriefingRoomLayout
