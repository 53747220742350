import {useCallback, useState} from 'react'
import {useCheckedCorrectClues} from '../components/ClueStateProvider'
import {Clue} from '../domain/clue'

function useRevealedClues(): Readonly<
  [revealedClues: ReadonlyArray<Clue>, revealClues: () => void]
> {
  const checkedCorrectClues = useCheckedCorrectClues()
  const [revealedClues, setRevealedClues] = useState(() => checkedCorrectClues)
  const revealClues = useCallback(() => {
    setRevealedClues(checkedCorrectClues)
  }, [checkedCorrectClues])
  return [revealedClues, revealClues]
}

export default useRevealedClues
