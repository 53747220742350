import {useEffect, useMemo, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import type Level from '../domain/level'
import handleAudioLoadError from '../utils/handleAudioLoadError'
import handleAudioPlayError from '../utils/handleAudioPlayError'
import Button from './Button'
import {useClueActions} from './ClueStateProvider'
import CorrectAnswerDialog from './CorrectAnswerDialog'
import IncorrectAnswerDialog from './IncorrectAnswerDialog'
import {useLevelActions, useLevelState} from './LevelStateProvider'

type Props = Readonly<{
  level: Level
  correctAnswerText: string
  onCorrectAnswerClose: () => void
  onIncorrectAnswerClose: () => void
}>

function CheckAnswerButton({
  level,
  correctAnswerText,
  onCorrectAnswerClose,
  onIncorrectAnswerClose,
}: Props): React.JSX.Element {
  const correctAnswerDialogRef = useRef<HTMLDialogElement>(null)
  const incorrectAnswerDialogRef = useRef<HTMLDialogElement>(null)
  const navigate = useNavigate()
  const {checkAnswer} = useClueActions()
  const {completeLevel} = useLevelActions()
  const levelState = useLevelState(level)
  const levelComplete = levelState === 'COMPLETE'
  const howl = useMemo(() => {
    return new Howl({
      src: ['/sounds/chapter-complete.webm', '/sounds/chapter-complete.mp3'],
      preload: true,
      onloaderror: handleAudioLoadError,
      onplayerror: handleAudioPlayError,
    })
  }, [])

  useEffect(() => {
    const dialogEl = correctAnswerDialogRef.current
    if (dialogEl == null) {
      return
    }
    dialogEl.addEventListener('close', onCorrectAnswerClose)
    return () => {
      dialogEl.removeEventListener('close', onCorrectAnswerClose)
    }
  }, [onCorrectAnswerClose])

  useEffect(() => {
    const dialogEl = incorrectAnswerDialogRef.current
    if (dialogEl == null) {
      return
    }
    dialogEl.addEventListener('close', onIncorrectAnswerClose)
    return () => {
      dialogEl.removeEventListener('close', onIncorrectAnswerClose)
    }
  }, [onIncorrectAnswerClose])

  return (
    <>
      <Button
        onClick={() => {
          if (levelComplete) {
            navigate(`/attic?completed=${level}`)
            return
          }
          if (checkAnswer()) {
            howl.play()
            completeLevel(level)
            correctAnswerDialogRef.current?.showModal()
          } else {
            incorrectAnswerDialogRef.current?.showModal()
          }
        }}
      >
        {levelComplete ? 'CONTINUE' : 'CHECK ANSWER'}
      </Button>
      <IncorrectAnswerDialog ref={incorrectAnswerDialogRef} />
      <CorrectAnswerDialog
        ref={correctAnswerDialogRef}
        text={correctAnswerText}
        onNext={() => {
          correctAnswerDialogRef.current?.close()
        }}
      />
    </>
  )
}

export default CheckAnswerButton
