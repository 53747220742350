type Props = Readonly<{
  revealed?: boolean
  children: string
}>

function IndecipherableText({
  revealed = false,
  children,
}: Props): React.JSX.Element {
  if (revealed) {
    return (
      <span className="decipherable whitespace-nowrap text-secondary-4">
        {children}
      </span>
    )
  }
  return (
    <span
      className="indecipherable select-none whitespace-nowrap"
      data-text={children}
      aria-label="indecipherable"
    />
  )
}

export default IndecipherableText
