import {forwardRef} from 'react'
import Button from './Button'
import Dialog from './Dialog'

type Props = Readonly<{
  text: string
  onNext: () => void
}>

const CorrectAnswerDialog = forwardRef<HTMLDialogElement, Props>(
  function CorrectAnswerDialog({text, onNext}, ref): React.JSX.Element {
    return (
      <Dialog ref={ref}>
        <img
          alt=""
          className="absolute bottom-0 left-0"
          src="/stamp.svg"
          width={152}
          height={139}
        />
        <div className="mx-4 my-5">
          <hr className="border-tertiary-3 opacity-20" />
          <div className="mt-3 flex items-center justify-center">
            <div className="space-y-4 text-center text-xs font-bold tracking-wider tall:text-sm">
              <p>Well done, your tagged clues match the story!</p>
              <p>{text}</p>
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <Button onClick={onNext}>NEXT</Button>
          </div>
        </div>
      </Dialog>
    )
  },
)

export default CorrectAnswerDialog
