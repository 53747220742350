import {useCorrectClueCount, useTaggedClueCount} from './ClueStateProvider'
import CrosshairIcon from './CrosshairIcon'

function CluesTagged(): React.JSX.Element {
  const taggedCount = useTaggedClueCount()
  const correctCount = useCorrectClueCount()
  return (
    <div className="flex rounded-xl border-2 border-button-border bg-secondary-3">
      <div className="flex items-center justify-center rounded-s-xl bg-secondary-2 px-4 text-sm font-bold">
        Clues Tagged:
      </div>
      <div className="flex flex-col items-center justify-center px-2 py-1">
        <CrosshairIcon className="h-5 w-5" aria-hidden />
        <span className="min-w-11 text-center text-sm font-extrabold">
          {taggedCount} / {correctCount}
        </span>
      </div>
    </div>
  )
}

export default CluesTagged
