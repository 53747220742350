type Props = React.ComponentProps<'svg'>

function CrosshairIcon(props: Props): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66699 7.83333V0.5H11.3337V7.83333H8.66699Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66699 20.5L8.66699 13.1667L11.3337 13.1667L11.3337 20.5L8.66699 20.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.16669H7.33333V11.8334H0V9.16669Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.667 9.16669L20.0003 9.16669L20.0003 11.8334L12.667 11.8334L12.667 9.16669Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.97887C6.95077 4.97887 4.47887 7.45077 4.47887 10.5C4.47887 13.5492 6.95077 16.0211 10 16.0211C13.0492 16.0211 15.5211 13.5492 15.5211 10.5C15.5211 7.45077 13.0492 4.97887 10 4.97887ZM2 10.5C2 6.08172 5.58172 2.5 10 2.5C14.4183 2.5 18 6.08172 18 10.5C18 14.9183 14.4183 18.5 10 18.5C5.58172 18.5 2 14.9183 2 10.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CrosshairIcon
