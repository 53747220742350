import {Clue} from '../domain/clue'
import useClueDialogRef from '../hooks/useClueDialogRef'
import InfoDialog from './InfoDialog'

type Props = Readonly<{
  clue: Clue
  imageUrl: string
  children?: React.ReactNode
  onClose?: (clue: Clue) => void
}>

function ClueDialog({
  clue,
  imageUrl,
  children,
  onClose,
}: Props): React.JSX.Element {
  const ref = useClueDialogRef(clue, onClose)
  return (
    <InfoDialog ref={ref} imageUrl={imageUrl}>
      {children}
    </InfoDialog>
  )
}

export default ClueDialog
