type Props = React.ComponentProps<'svg'>

function AlertIcon(props: Props): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 6 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.4001 10.3999H1.2001L0.600098 0.199902H6.0001L5.4001 10.3999Z"
        fill="currentColor"
      />
      <path
        d="M5.4002 16.3999H1.2002V12.7999H5.4002V16.3999Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AlertIcon
