import * as Sentry from '@sentry/browser'
import {useNavigate} from 'react-router-dom'
import Button from './Button'
import {useLevelActions} from './LevelStateProvider'

function StartPage(): React.JSX.Element {
  const navigate = useNavigate()
  const {resetLevelStates} = useLevelActions()
  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center gap-4 bg-[#47434399]">
      <img
        className="absolute top-0"
        src="/embassy-logo.webp"
        width={136}
        height={83}
        alt=""
      />
      <img
        className="mt-8 object-contain tall:mt-0"
        src="/logo.webp"
        width={494}
        height={104}
        alt="Heroes of World War 2 Uncovered"
      />
      <Button
        onClick={() => {
          // Clear all existing game data.
          try {
            sessionStorage.removeItem('level-states')
            sessionStorage.removeItem('briefing-room-clue-states')
            sessionStorage.removeItem('field-clue-states')
            sessionStorage.removeItem('office-clue-states')
          } catch (err) {
            // Squash and log error.
            Sentry.captureException(err)
            console.error(err)
          }
          resetLevelStates()

          navigate('/attic/letter')
          gtag('event', 'start_game')
        }}
      >
        START
      </Button>
    </div>
  )
}

export default StartPage
