import {forwardRef} from 'react'
import Container from './Container'

type Props = Readonly<{
  children: React.ReactNode
}>

const Dialog = forwardRef<HTMLDialogElement, Props>(function Dialog(
  {children},
  ref,
): React.JSX.Element {
  return (
    <dialog
      ref={ref}
      className="animate__animated animate__fadeIn animate__faster max-w-[480px] bg-transparent text-tertiary-3"
    >
      <Container>{children}</Container>
    </dialog>
  )
})

export default Dialog
