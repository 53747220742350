import {Outlet} from 'react-router-dom'
import {OfficeClue} from '../domain/clue'
import type ClueState from '../domain/clueState'
import useLoopingSound from '../hooks/useLoopingSound'
import ClueStateProvider from './ClueStateProvider'
import Scene from './Scene'

const INITIAL_CLUE_STATES: Readonly<Record<OfficeClue, ClueState>> = {
  'air-bases': 'NOT_INVESTIGATED',
  'ltc-benjamin-davis': 'NOT_INVESTIGATED',
  'aviators-cap': 'NOT_INVESTIGATED',
  'flying-cross': 'NOT_INVESTIGATED',
  'p51-mustang': 'NOT_INVESTIGATED',
  'west-point-graduate': 'NOT_INVESTIGATED',
  'tuskegee-airmen': 'NOT_INVESTIGATED',
}

const CORRECT_CLUES: ReadonlyArray<OfficeClue> = [
  'ltc-benjamin-davis',
  'p51-mustang',
  'flying-cross',
  'west-point-graduate',
]

const AMBIENCE_SRC = [
  '/sounds/office-ambience.webm',
  '/sounds/office-ambience.mp3',
]

const MUSIC_SRC = ['/sounds/level-music.webm', '/sounds/level-music.mp3']

function OfficeLayout(): React.JSX.Element {
  useLoopingSound(AMBIENCE_SRC)
  useLoopingSound(MUSIC_SRC)

  return (
    <main className="flex h-full items-center justify-center">
      <Scene
        backgroundSrc="/office.webp"
        backgroundSrcSet="/office-640w.webp 640w, /office-1280w.webp 1280w, /office-2560w.webp 2560w, /office.webp 3560w"
        backgroundAlt="An office interior, featuring large windows which look out onto an army airfield with several fighter planes visible. A uniformed Black American male stands in the foreground, along with a desk containing various documents, photos and pieces of equipment."
        width={3840}
        height={2160}
      >
        <ClueStateProvider
          level="office"
          initialClueStates={INITIAL_CLUE_STATES}
          correctClues={CORRECT_CLUES}
        >
          <Outlet />
        </ClueStateProvider>
      </Scene>
    </main>
  )
}

export default OfficeLayout
