import {Helmet} from 'react-helmet-async'
import {useNavigate} from 'react-router-dom'
import shuffleIn from '../sounds/shuffleIn'
import Button from './Button'
import ClueHotspot from './ClueHotspot'
import ClueText from './ClueText'
import HelperText from './HelperText'
import LevelTutorialDialog from './LevelTutorialDialog'

function BriefingRoomPage(): React.JSX.Element {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>Briefing Room</title>
      </Helmet>
      <h1 className="sr-only">Briefing Room</h1>
      <div className="absolute bottom-0 w-full text-center">
        <HelperText>Tap on the CLUE icons to explore this scene.</HelperText>
      </div>
      <div className="absolute left-[59%] top-[52%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot clue="decoy-radio" imageUrl="/decoy-radio-clue.webp">
          <p>
            A <ClueText>decoy radio</ClueText> broadcasting plans, troop
            movements and other sensitive details ... all false.
          </p>
          <p>
            Historical evidence confirms that fake radio traffic was extremely
            effective in fooling German command before D-Day.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[78%] top-[71%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot
          clue="mysterious-woman"
          imageUrl="/mysterious-woman-clue.webp"
        >
          <p>
            This <ClueText>mysterious woman</ClueText> claims to be an orderly.
          </p>
          <p>
            British women were not allowed to serve in combat during World War
            II, but assumed a variety of auxiliary duties in the armed forces --
            nursing, radar operations, codebreaking, transport piloting ... and
            spycraft.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[10%] top-[30%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot clue="pas-de-calais" imageUrl="/pas-de-calais-clue.webp">
          <p>
            An oddly conspicuous note attached to a map of the French{' '}
            <ClueText>Pas-de-Calais</ClueText> region, with instructions for
            troop assembly.
          </p>
          <p>
            False reports of an invasion at Pas-de-Calais, rather than Normandy,
            were a crucial Allied deception during D-Day preparations.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[6%] top-[59%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot
          clue="pair-of-wirecutters"
          imageUrl="/pair-of-wirecutters-clue.webp"
        >
          <p>
            A <ClueText>pair of wire cutters</ClueText>. Often used during World
            War II for sabotage operations to disrupt enemy communications,
            transport and supply lines.
          </p>
          <p>Strange equipment for this woman to have.</p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[64.5%] top-[36%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot
          clue="pays-de-la-loire"
          imageUrl="/pays-de-la-loire-clue.webp"
        >
          <p>
            An operations map of France, with the{' '}
            <ClueText>Pays de la Loire</ClueText> region marked.
          </p>
          <p>
            It looks like the Brits were planning to do something here shortly
            before D-Day.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[25%] top-[82%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot clue="blanche" imageUrl="/blanche-clue.webp">
          <p>
            A cryptic file on someone called &quot;<ClueText>Blanche</ClueText>
            .&quot;
          </p>
          <p>
            39 women served Britain as spies during the War. Is this one of
            them?
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute right-0 top-0 m-2 flex items-start gap-5">
        <Button
          onClick={() => {
            navigate('/briefing-room/letter')
            shuffleIn.play()
          }}
        >
          VIEW LETTER
        </Button>
      </div>
      <LevelTutorialDialog />
    </>
  )
}

export default BriefingRoomPage
