import ClueState from '../domain/clueState'
import InvestigatedClueButton from './InvestigatedClueButton'
import NotInvestigatedClueButton from './NotInvestigatedClueButton'

type Props = Readonly<{
  name: string
  state: ClueState
  onInvestigate: () => void
}>

function Clue({state, name, onInvestigate}: Props): React.JSX.Element {
  if (ClueState.investigated(state)) {
    return <InvestigatedClueButton label={name} onClick={onInvestigate} />
  }
  return <NotInvestigatedClueButton label={name} onClick={onInvestigate} />
}

export default Clue
