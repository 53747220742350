import {forwardRef} from 'react'
import IconButton from './IconButton'
import MenuIcon from './MenuIcon'

type Props = React.ComponentProps<'button'>

const MenuButton = forwardRef<HTMLButtonElement, Props>(
  function MenuButton(props, ref): React.JSX.Element {
    return (
      <IconButton
        ref={ref}
        icon={
          <MenuIcon
            className="h-6 w-6 tall:h-[30px] tall:w-[30px]"
            aria-hidden
          />
        }
        label="Menu"
        shape="square"
        {...props}
      />
    )
  },
)

export default MenuButton
