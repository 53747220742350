import * as Sentry from '@sentry/browser'
import {Howler} from 'howler'
import {createContext, useContext, useEffect, useMemo, useState} from 'react'

type Props = Readonly<{
  children: React.ReactNode
}>

type MutedState = Readonly<[muted: boolean, setMuted: (muted: boolean) => void]>

const MutedContext = createContext<MutedState | undefined>(undefined)

function SoundProvider({children}: Props): React.JSX.Element {
  const [muted, setMuted] = useState(() => {
    try {
      const persistedMuted = sessionStorage.getItem('muted')
      if (persistedMuted != null) {
        return JSON.parse(persistedMuted) as unknown as boolean
      }
    } catch (err) {
      // Squash and log error.
      Sentry.captureException(err)
      console.error(err)
    }
    return false
  })

  useEffect(() => {
    Howler.mute(muted)
    try {
      sessionStorage.setItem('muted', JSON.stringify(muted))
    } catch (err) {
      // Squash and log error.
      Sentry.captureException(err)
      console.error(err)
    }
  }, [muted])

  const mutedValue = useMemo<MutedState>(
    () => [
      muted,
      (muted: boolean) => {
        setMuted(muted)
        if (muted) {
          gtag('event', 'mute')
        } else {
          gtag('event', 'unmute')
        }
      },
    ],
    [muted, setMuted],
  )

  return (
    <MutedContext.Provider value={mutedValue}>{children}</MutedContext.Provider>
  )
}

export function useMuted(): MutedState {
  const context = useContext(MutedContext)
  if (context === undefined) {
    throw new Error('useMuted must be used within a SoundProvider')
  }
  return context
}

export default SoundProvider
