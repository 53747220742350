import * as Sentry from '@sentry/react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import type {LevelState} from '../domain/level'
import Level from '../domain/level'

type LevelStates = Partial<Record<Level, LevelState>>

type LevelActions = Readonly<{
  resetLevelStates: () => void
  completeLevel: (level: Level) => void
}>

type Props = Readonly<{
  children: React.ReactNode
}>

const LevelStatesContext = createContext<LevelStates | undefined>(undefined)

const LevelActionsContext = createContext<LevelActions | undefined>(undefined)

function LevelStateProvider({children}: Props): React.JSX.Element {
  const [levelStates, setLevelStates] = useState<LevelStates>(() => {
    try {
      const persistedLevelStates = sessionStorage.getItem('level-states')
      if (persistedLevelStates != null) {
        return JSON.parse(persistedLevelStates) as unknown as LevelStates
      }
    } catch (err) {
      // Squash and log error.
      Sentry.captureException(err)
      console.error(err)
    }
    return {}
  })

  useEffect(() => {
    try {
      sessionStorage.setItem('level-states', JSON.stringify(levelStates))
    } catch (err) {
      // Squash and log error.
      Sentry.captureException(err)
      console.error(err)
    }
  }, [levelStates])

  const completeLevel = useCallback((level: Level) => {
    setLevelStates((levelStates) => ({
      ...levelStates,
      [level]: 'COMPLETE',
    }))
    gtag('event', 'complete_level', {level})
  }, [])

  const resetLevelStates = useCallback(() => {
    setLevelStates({})
  }, [])

  const levelActions = useMemo(
    () => ({completeLevel, resetLevelStates}),
    [completeLevel, resetLevelStates],
  )

  return (
    <LevelActionsContext.Provider value={levelActions}>
      <LevelStatesContext.Provider value={levelStates}>
        {children}
      </LevelStatesContext.Provider>
    </LevelActionsContext.Provider>
  )
}

export function useLevelActions(): LevelActions {
  const context = useContext(LevelActionsContext)
  if (context === undefined) {
    throw new Error('useLevelActions must be used within a LevelStateProvider')
  }
  return context
}

export function useLevelState(level: Level): LevelState | undefined {
  const context = useContext(LevelStatesContext)
  if (context === undefined) {
    throw new Error('useLevelState must be used within a LevelStateProvider')
  }
  return context[level]
}

export function useLevelsComplete(): boolean {
  const context = useContext(LevelStatesContext)
  if (context === undefined) {
    throw new Error(
      'useLevelsComplete must be used within a LevelStateProvider',
    )
  }
  return (
    context['briefing-room'] === 'COMPLETE' &&
    context['field'] === 'COMPLETE' &&
    context['office'] === 'COMPLETE'
  )
}

export function useNextLevel(): Level | null {
  const context = useContext(LevelStatesContext)
  if (context === undefined) {
    throw new Error('useNextLevel must be used within a LevelStateProvider')
  }
  return Level.next(context)
}

export default LevelStateProvider
