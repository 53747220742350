import * as Sentry from '@sentry/browser'
import type {SendFeedbackParams} from '@sentry/types'
import {forwardRef, useState} from 'react'
import AlertIcon from './AlertIcon'
import Button from './Button'
import CloseIcon from './CloseIcon'
import FlatIcon from './FlatIcon'
import IconButton from './IconButton'
import Menu from './Menu'

const FeedbackDialog = forwardRef<HTMLDialogElement>(
  function FeedbackDialog(_props, ref): React.JSX.Element {
    const [submitted, setSubmitted] = useState(false)
    return (
      <dialog
        className="absolute inset-0 m-0 h-full max-h-full w-full max-w-full bg-transparent text-tertiary-3"
        ref={ref}
      >
        <div className="flex h-full w-full items-center justify-center">
          <div className="relative flex h-full max-h-[56.25vw] w-full max-w-[177.78vh] items-center justify-center overflow-y-auto bg-container-fill">
            <div className="flex h-full w-full flex-col items-center overflow-y-auto tall:justify-center">
              <hr className="mt-4 w-full max-w-md border-tertiary-3 opacity-20" />
              <h1 className="mt-3 flex items-center gap-2 text-sm font-bold">
                <FlatIcon
                  icon={<AlertIcon className="h-full w-full" aria-hidden />}
                  size="sm"
                  variant="alert"
                />{' '}
                Report a bug
              </h1>
              <form
                id="feedback"
                className="mt-4 w-full max-w-sm space-y-4"
                onSubmit={(ev) => {
                  ev.preventDefault()
                  // @ts-expect-error: `ev.target` is the `<form>` element.
                  const formData = new FormData(ev.target)
                  const eventId = Sentry.captureMessage('User Feedback')
                  const userFeedback: SendFeedbackParams = {
                    associatedEventId: eventId,
                    message: getStringValue(formData.get('message')) as string,
                  }
                  const name = getStringValue(formData.get('name'))
                  if (name != null) {
                    userFeedback.name = name
                  }
                  const email = getStringValue(formData.get('email'))
                  if (email != null) {
                    userFeedback.email = email
                  }
                  Sentry.captureFeedback(userFeedback)
                  setSubmitted(true)
                }}
              >
                <div className="flex flex-col">
                  <label className="text-xs font-bold" htmlFor="name">
                    Name <span className="font-medium">(optional)</span>:
                  </label>
                  <input
                    id="name"
                    name="name"
                    className="mt-0.5 rounded-[4px] border border-tertiary-3/20 bg-transparent px-1.5 py-1 text-sm"
                    type="text"
                    autoComplete="name"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-xs font-bold" htmlFor="email">
                    Email <span className="font-medium">(optional)</span>:
                  </label>
                  <input
                    id="email"
                    name="email"
                    className="mt-0.5 rounded-[4px] border border-tertiary-3/20 bg-transparent px-1.5 py-1 text-sm"
                    type="email"
                    autoComplete="email"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-xs font-bold" htmlFor="message">
                    Message:
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="mt-0.5 rounded-[4px] border border-tertiary-3/20 bg-transparent px-1.5 py-1 text-sm"
                    required
                    rows={5}
                  />
                </div>
              </form>
              {submitted && (
                <div
                  className="mt-4 rounded-xl bg-secondary-2 p-4"
                  aria-live="assertive"
                >
                  Feedback sent! Thank you for helping us improve.
                </div>
              )}
            </div>
            <div className="absolute left-0 top-0 m-2">
              <Menu />
            </div>
            <div className="absolute right-0 top-0 m-2">
              <form method="dialog">
                <IconButton
                  icon={
                    <CloseIcon
                      className="h-6 w-6 tall:h-[30px] tall:w-[30px]"
                      aria-hidden
                    />
                  }
                  label="Close"
                  shape="square"
                />
              </form>
            </div>
            <div className="absolute bottom-0 right-0 m-2">
              <Button type="submit" form="feedback">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </dialog>
    )
  },
)

function getStringValue(value: FormDataEntryValue | null): string | undefined {
  if (typeof value === 'string') {
    return value
  }
  return undefined
}

export default FeedbackDialog
