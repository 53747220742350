type Props = Readonly<{
  children: string
}>

function HelperText({children}: Props): React.JSX.Element {
  return (
    <svg className="h-12 w-full" xmlns="http://www.w3.org/2000/svg">
      <text
        className="text-sm font-semibold"
        x="50%"
        y="50%"
        fill="white"
        stroke="currentColor"
        strokeWidth="3px"
        letterSpacing="0.84px"
        paintOrder="stroke"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {children}
      </text>
    </svg>
  )
}

export default HelperText
