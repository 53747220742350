import {Helmet} from 'react-helmet-async'
import {useNavigate} from 'react-router-dom'
import Button from './Button'

function PhoneCallPage(): React.JSX.Element {
  const navigate = useNavigate()
  return (
    <>
      <Helmet>
        <title>Phone Call</title>
      </Helmet>
      <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-[#47434399] backdrop-blur-xl">
        <div className="flex h-full max-h-96 w-3/4 max-w-screen-md flex-col justify-around text-xs font-semibold">
          <LeftBubble delay={1}>&quot;Hello?&quot;</LeftBubble>
          <RightBubble delay={3}>
            &quot;Ummm… this may sound strange, but do you know someone named
            Rich? I&apos;m the great-grandchild of--&quot;
          </RightBubble>
          <LeftBubble delay={7}>&quot;Frank?&quot;</LeftBubble>
          <RightBubble delay={9}>&quot;How did you--&quot;</RightBubble>
          <LeftBubble delay={11}>
            &quot;I&apos;ve got a whole pile of unsent letters my Grandpa wrote
            to his pal, Frank the Yank …&quot;
          </LeftBubble>
        </div>
        <div
          className="animate__animated animate__fadeIn animate__delay-2s absolute bottom-0 right-0 p-2"
          style={{'--animate-delay': '7.5s'} as React.CSSProperties}
        >
          <Button
            onClick={() => {
              navigate('/?share')
              gtag('event', 'complete_game')
            }}
          >
            NEXT
          </Button>
        </div>
      </div>
    </>
  )
}

function LeftBubble({
  delay,
  children,
}: Readonly<{delay: number; children: React.ReactNode}>): React.JSX.Element {
  return (
    <p
      className="animate__animated animate__fadeIn animate__delay-2s max-w-[270px] self-start rounded-xl border-2 border-button-border bg-container-fill p-3 tall:max-w-96"
      style={
        {'--animate-delay': `${String(delay / 2)}s`} as React.CSSProperties
      }
      lang="en-GB"
    >
      {children}
    </p>
  )
}

function RightBubble({
  delay,
  children,
}: Readonly<{delay: number; children: React.ReactNode}>): React.JSX.Element {
  return (
    <p
      className="animate__animated animate__fadeIn animate__delay-2s max-w-[270px] self-end rounded-xl border-2 border-button-border bg-primary-1 p-3 shadow-container tall:max-w-96"
      style={
        {'--animate-delay': `${String(delay / 2)}s`} as React.CSSProperties
      }
      lang="en-US"
    >
      {children}
    </p>
  )
}

export default PhoneCallPage
