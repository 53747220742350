import {useEffect, useState} from 'react'
import useFullscreen, {SUPPORTS_FULLSCREEN} from '../hooks/useFullscreen'
import Button from './Button'
import Container from './Container'
import FullscreenIcon from './FullscreenIcon'

const MEDIA_QUERY = '(orientation: landscape) or (min-width: 640px)'

function ForceLandscape({children}: Readonly<{children: React.ReactNode}>) {
  const forceLandscape = useForceLandscape()
  const [fullscreen, toggleFullscreen] = useFullscreen()
  return (
    <>
      {children}
      {forceLandscape && (
        <div className="absolute inset-0 z-50 flex flex-col items-center justify-around backdrop-blur-lg">
          <div className="max-w-72">
            <Container>
              <div className="flex flex-col items-center gap-6 px-3 py-6">
                <OrientationIcon />
                <p className="text-center text-[15px] font-semibold">
                  Please unlock / enable screen rotation and{' '}
                  <span className="font-bold">switch to landscape mode</span> to
                  continue
                </p>
              </div>
            </Container>
          </div>
          {SUPPORTS_FULLSCREEN && !fullscreen && (
            <Button
              icon={
                <FullscreenIcon className="h-[30px] w-[30px]" aria-hidden />
              }
              onClick={() => {
                toggleFullscreen()
              }}
            >
              ENTER FULLSCREEN
            </Button>
          )}
        </div>
      )}
    </>
  )
}

function useForceLandscape(): boolean {
  const [forceLandscape, setForceLandscape] = useState(
    () => !matchMedia(MEDIA_QUERY).matches,
  )
  useEffect(() => {
    const mediaQueryList = matchMedia(MEDIA_QUERY)
    function handleMediaChange(ev: MediaQueryListEvent) {
      setForceLandscape(!ev.matches)
    }
    mediaQueryList.addEventListener('change', handleMediaChange)
    return () => {
      mediaQueryList.removeEventListener('change', handleMediaChange)
    }
  }, [])
  return forceLandscape
}

function OrientationIcon(): React.JSX.Element {
  return (
    <svg
      width="110"
      height="96"
      viewBox="0 0 110 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2979 69.2642L20.2979 26.488L89.6333 26.488V69.2642H20.2979ZM28.492 62.1764V33.576L73.8753 33.576V62.1764H28.492ZM79.17 56.2075H84.2126V39.5447L79.17 39.5447V56.2075Z"
        fill="currentColor"
      />
      <path
        d="M1.90422 22.1423C0.647056 25.136 3.91749e-08 28.3447 0 31.5852H8.00508C8.00508 22.3182 15.6211 14.8057 25.0159 14.8057L41.0259 14.8055L41.0259 21.7145L55.0349 10.9163L41.0259 0L41.0259 6.90937H24.0151V6.92938C21.0716 7.04562 18.1692 7.67395 15.4427 8.78794C12.4077 10.028 9.64994 11.8456 7.32699 14.1369C5.00405 16.4283 3.16139 19.1485 1.90422 22.1423Z"
        fill="currentColor"
      />
      <path
        d="M108.096 73.8577C109.353 70.8639 110 67.6552 110 64.4148L101.995 64.4148C101.995 73.6817 94.3787 81.1944 84.9839 81.1945L68.9737 81.1946L68.9738 74.2854L54.9648 85.0836L68.9738 95.9999L68.9737 89.0908H85.9846V89.0706C88.9282 88.9544 91.8306 88.326 94.5571 87.212C97.5922 85.972 100.35 84.1544 102.673 81.8631C104.996 79.5717 106.838 76.8515 108.096 73.8577Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ForceLandscape
