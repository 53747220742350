import {useSearchParams} from 'react-router-dom'
import type Level from '../domain/level'
import useItemDialogRef from '../hooks/useItemDialogRef'
import InfoDialog from './InfoDialog'
import ItemButton from './ItemButton'

type Props = Readonly<{
  level: Level
  imageUrl: string
  name: string
  pointerPosition: 'top-left' | 'top-right'
  children: React.ReactNode
}>

function ItemHotspot({
  name,
  level,
  imageUrl,
  pointerPosition,
  children,
}: Props): React.JSX.Element {
  const dialogRef = useItemDialogRef(level)
  const [, setSearchParams] = useSearchParams()
  return (
    <>
      <ItemButton
        name={name}
        pointerPosition={pointerPosition}
        onClick={() => {
          setSearchParams(
            (searchParams) => {
              searchParams.set('item', '')
              return searchParams
            },
            {replace: true},
          )
          gtag('event', 'investigate_item', {level})
        }}
      />
      <InfoDialog ref={dialogRef} imageUrl={imageUrl}>
        {children}
      </InfoDialog>
    </>
  )
}

export default ItemHotspot
