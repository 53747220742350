import React from 'react'
import {Helmet} from 'react-helmet-async'
import EpilogueLetter from './EpilogueLetter'
import {LetterPageLayout} from './LetterPageLayout'

function EpilogueLetterPage(): React.JSX.Element {
  return (
    <>
      <Helmet>
        <title>Epilogue Letter</title>
      </Helmet>
      <LetterPageLayout onNextUrl="/epilogue">
        <EpilogueLetter />
      </LetterPageLayout>
    </>
  )
}

export default EpilogueLetterPage
