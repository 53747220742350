import {Howl} from 'howler'
import {useEffect} from 'react'
import handleAudioLoadError from '../utils/handleAudioLoadError'

function useLoopingSound(src: string | Array<string>): void {
  useEffect(() => {
    let mounted = true
    let visible = !document.hidden
    const howl = new Howl({
      src,
      html5: true,
      loop: true,
      onloaderror: handleAudioLoadError,
      onplayerror(_soundId, error) {
        console.error('Failed to play sound', error)
        howl.once('unlock', () => {
          if (
            mounted &&
            visible &&
            howl.state() === 'loaded' &&
            !howl.playing()
          ) {
            howl.play()
          }
        })
      },
    })
    howl.on('load', () => {
      if (mounted && visible) {
        howl.play()
        howl.fade(0, 1, 1500)
      }
    })
    function handleVisibilityChange() {
      if (document.hidden) {
        visible = false
        if (mounted && howl.playing()) {
          howl.pause()
        }
      } else {
        visible = true
        if (mounted && howl.state() === 'loaded' && !howl.playing()) {
          howl.play()
        }
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      mounted = false
      if (visible && howl.playing()) {
        howl.fade(1, 0, 1000)
        howl.once('fade', () => {
          howl.unload()
        })
      } else {
        howl.unload()
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [src])
}

export default useLoopingSound
