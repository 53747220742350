import * as Sentry from '@sentry/browser'
import {forwardRef, useState} from 'react'
import Button from './Button'
import CloseIcon from './CloseIcon'
import Container from './Container'
import IconButton from './IconButton'
import Menu from './Menu'

const SocialSharingDialog = forwardRef<HTMLDialogElement>(
  function SocialSharingDialog(_props, ref): React.JSX.Element {
    const [copied, setCopied] = useState(false)

    async function webShareOrCopyToClipboard(): Promise<void> {
      const shareData = {
        title: 'Heroes of World War II Uncovered',
        text: "Crack the code of your great-grandfather's old war correspondence in this interactive detective game, exploring the untold stories of World War 2 through the eyes of a new generation.",
        url: 'https://www.uncoverd.day',
      } as const satisfies ShareData

      if ('canShare' in navigator && navigator.canShare(shareData)) {
        await navigator.share(shareData)
      } else {
        await navigator.clipboard.writeText(shareData.url)
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 2000)
      }
    }

    return (
      <dialog
        className="absolute inset-0 m-0 h-full max-h-full w-full max-w-full bg-transparent text-tertiary-3"
        ref={ref}
      >
        <div className="flex h-full w-full items-center justify-center">
          <div className="relative flex h-full max-h-[56.25vw] w-full max-w-[177.78vh] items-center justify-center">
            <div className="flex h-full items-center justify-center overflow-y-auto">
              <Container className="max-w-[480px]">
                <div className="flex flex-col items-center p-4">
                  <hr className="w-full border-tertiary-3 opacity-20" />
                  <p className="mt-11 text-sm font-bold">
                    Please share this experience with family and friends!
                  </p>
                  <div className="mt-3">
                    <Button
                      icon={
                        <LinkIcon
                          className="inline h-6 w-6 tall:h-[30px] tall:w-[30px]"
                          aria-hidden
                        />
                      }
                      onClick={() => {
                        webShareOrCopyToClipboard().catch((err: unknown) => {
                          if (
                            err instanceof Error &&
                            err.name === 'AbortError'
                          ) {
                            // Ignore abort errors caused by cancellation of share.
                            return
                          }
                          // Squash and log error.
                          Sentry.captureException(err)
                          console.error(err)
                        })
                      }}
                    >
                      {copied ? 'COPIED!' : 'SHARE'}
                    </Button>
                  </div>
                  <div className="mt-3 flex flex-col items-center rounded-lg bg-secondary-3 px-2 py-3 tall:mt-11">
                    <p className="text-sm font-semibold">Thanks for playing!</p>
                    <div className="mt-1.5 flex items-center justify-center gap-0.5 rounded-lg text-left text-xs ">
                      <InstagramIcon aria-hidden className="h-6 w-6" />
                      <p className="text-[10px] font-semibold">
                        Stay in touch with the U.S. Embassy in London on
                        Instagram{' '}
                        <a
                          href="https://www.instagram.com/usa_in_uk/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          @usa_in_uk
                        </a>
                      </p>
                    </div>
                  </div>
                  <p className="mt-2 text-[7px]">
                    Source: Untitled photo shows: Tuskegee airmen training,
                    Tuskegee, Alabama. , 1942. [Photograph] Retrieved from the
                    Library of Congress, https://www.loc.gov/item/2017767766/.
                  </p>
                </div>
              </Container>
            </div>
            <div className="absolute left-0 top-0 m-2">
              <Menu />
            </div>
            <img
              className="absolute left-[50%] top-0 -translate-x-1/2"
              src="/embassy-logo.webp"
              width={136}
              height={83}
              alt=""
            />
            <div className="absolute right-0 top-0 m-2">
              <form method="dialog">
                <IconButton
                  icon={
                    <CloseIcon
                      className="h-6 w-6 tall:h-[30px] tall:w-[30px]"
                      aria-hidden
                    />
                  }
                  label="Close"
                  shape="square"
                />
              </form>
            </div>
          </div>
        </div>
      </dialog>
    )
  },
)

function InstagramIcon(props: React.ComponentProps<'svg'>): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"
        fill="currentColor"
      />
    </svg>
  )
}
function LinkIcon(props: React.ComponentProps<'svg'>): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1116_3026)">
        <path
          d="M10.9499 14.5073V14.5073C9.77829 13.3357 7.87748 13.3344 6.70591 14.506L3.12132 18.0906C1.94975 19.2622 1.94975 21.1617 3.12132 22.3332L7.36922 26.5811C8.54079 27.7527 10.4403 27.7527 11.6119 26.5811L15.1964 22.9965C16.368 21.825 16.3667 19.9242 15.1951 18.7526V18.7526"
          stroke="#333333"
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M14.5074 10.9498V10.9498C13.3358 9.77821 13.3345 7.8774 14.5061 6.70583L18.0907 3.12124C19.2622 1.94967 21.1617 1.94966 22.3333 3.12124L26.5812 7.36913C27.7528 8.5407 27.7528 10.4402 26.5812 11.6118L22.9966 15.1964C21.8251 16.3679 19.9242 16.3666 18.7527 15.195V15.195"
          stroke="#333333"
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <rect
          x="8.1084"
          y="18.3076"
          width="14.4234"
          height="4.6502"
          rx="2.3251"
          transform="rotate(-45 8.1084 18.3076)"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1116_3026">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SocialSharingDialog
