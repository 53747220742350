import {useEffect, useRef} from 'react'
import {useSearchParams} from 'react-router-dom'
import FeedbackDialog from './FeedbackDialog'
import Menu from './Menu'
import SocialSharingDialog from './SocialSharingDialog'

type Props = Readonly<{
  backgroundSrc: string
  backgroundSrcSet?: string
  backgroundAlt: string
  width: number
  height: number
  children?: React.ReactNode
}>

function Scene({
  backgroundSrc,
  backgroundSrcSet,
  backgroundAlt,
  width,
  height,
  children,
}: Props): React.JSX.Element {
  const feedbackDialogRef = useRef<HTMLDialogElement>(null)
  const socialSharingDialogRef = useRef<HTMLDialogElement>(null)

  const [searchParams] = useSearchParams()
  const showShareDialog = searchParams.has('share')

  useEffect(() => {
    if (showShareDialog) {
      socialSharingDialogRef.current?.showModal()
    } else {
      socialSharingDialogRef.current?.close('nav')
    }
  }, [showShareDialog])

  return (
    <>
      <div className="relative aspect-video max-h-full max-w-full bg-tertiary-3">
        <img
          className="animate__animated animate__fadeIn animate__slower aspect-video h-full object-cover object-center"
          src={backgroundSrc}
          srcSet={backgroundSrcSet}
          sizes="100vw"
          alt={backgroundAlt}
          width={width}
          height={height}
        />
        {children}
        <div className="absolute left-0 top-0 m-2">
          <Menu
            onFeedback={() => {
              feedbackDialogRef.current?.showModal()
            }}
            onShare={() => socialSharingDialogRef.current?.showModal()}
          />
        </div>
      </div>
      <FeedbackDialog ref={feedbackDialogRef} />
      <SocialSharingDialog ref={socialSharingDialogRef} />
    </>
  )
}

export default Scene
