export type BriefingRoomClue =
  | 'decoy-radio'
  | 'mysterious-woman'
  | 'pas-de-calais'
  | 'pair-of-wirecutters'
  | 'pays-de-la-loire'
  | 'blanche'

export type FieldClue =
  | 'jasper-maskelyne'
  | 'camouflage-engineers'
  | 'william-blass'
  | 'first-us-army-group'
  | 'operation-fortitude'
  | 'supply-crates'
  | 'inflatable-props'

export type OfficeClue =
  | 'air-bases'
  | 'ltc-benjamin-davis'
  | 'aviators-cap'
  | 'flying-cross'
  | 'p51-mustang'
  | 'west-point-graduate'
  | 'tuskegee-airmen'

export type Clue = BriefingRoomClue | FieldClue | OfficeClue

export const CLUE_NAMES: Readonly<Record<Clue, string>> = {
  'decoy-radio': 'Decoy Radio',
  'mysterious-woman': 'Mysterious Woman',
  'pas-de-calais': 'Pas-de-Calais',
  'pair-of-wirecutters': 'Pair of Wirecutters',
  'pays-de-la-loire': 'Pays de la Loire',
  blanche: 'Blanche',
  'jasper-maskelyne': 'Jasper Maskelyne',
  'camouflage-engineers': 'Camouflage Engineers',
  'william-blass': 'William Blass',
  'first-us-army-group': 'First U.S. Army Group',
  'operation-fortitude': 'Operation Fortitude',
  'supply-crates': 'Surplus Equipment',
  'inflatable-props': 'Inflatable Props',
  'air-bases': 'Air Bases',
  'ltc-benjamin-davis': 'Benjamin O. Davis Jr.',
  'aviators-cap': 'Aviator Helmet',
  'flying-cross': 'Flying Cross',
  'p51-mustang': 'P51 Mustang',
  'west-point-graduate': 'West Point Graduate',
  'tuskegee-airmen': 'Tuskegee Airmen',
}
