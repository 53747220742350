type Props = React.SVGProps<SVGSVGElement>

function FullscreenIcon(props: Props): React.JSX.Element {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 1L1 1L1 7L3 7L3 3L7 3L7 1Z" fill="currentColor" />
      <path
        d="M11 17L17 17L17 11L15 11L15 15L11 15L11 17Z"
        fill="currentColor"
      />
      <path d="M11 1L17 1L17 7L15 7L15 3L11 3L11 1Z" fill="currentColor" />
      <path d="M7 17L1 17L1 11L3 11L3 15L7 15L7 17Z" fill="currentColor" />
    </svg>
  )
}

export default FullscreenIcon
