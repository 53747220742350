import clsx from 'clsx'
import click from '../sounds/click'
import Pointer from './Pointer'

type Props = Readonly<{
  name: string
  pointerPosition: 'top-left' | 'top-right' | 'bottom-right'
  onClick?: () => void
}>

function ItemButton({
  name,
  pointerPosition,
  onClick,
}: Props): React.JSX.Element {
  return (
    <>
      <button
        className="h-16 w-16 rounded-full border-8 border-secondary-3/80 hover:border-secondary-4/80 active:scale-95"
        onClick={() => {
          click.play()
          onClick?.()
        }}
      >
        <span className="sr-only">{name}</span>
      </button>
      <Pointer
        className={clsx(
          'pointer-events-none absolute',
          pointerPosition === 'top-left' &&
            'top-0 -translate-x-1/2 -translate-y-1/2 rotate-180',
          pointerPosition === 'top-right' &&
            'top-0 -translate-y-1/2 translate-x-full -rotate-90',
          pointerPosition === 'bottom-right' &&
            'top-0 translate-x-full translate-y-full',
        )}
        aria-hidden
      />
    </>
  )
}

export default ItemButton
