import Letter from './Letter'

function IntroLetter(): React.JSX.Element {
  return (
    <Letter>
      <p>Dear Rich,</p>
      <p>
        Not a day goes by that I don&apos;t think of our friendship forged in
        the fires of battle on that fateful beach.
      </p>
      <p>
        As you never missed an opportunity to remind me, you were supposed to
        clear the way for us and then get the &apos;ell out of there. You Brits
        had plenty of fighting of your own to do at Gold, Juno and Sword. But, I
        am so glad you stayed and saved our butts. When your Donald Duck Tank
        got hit, you dropped down in the dirt and fought as well as any GI I
        ever met. You saved my life many times as I hope I did yours.
      </p>
      <p>
        I promised you that, if we made it through, I would tell you the story
        of the amazing things I saw, and the heroes I encountered leading up to
        our meeting on that longest day. I hope, more than anything, to one day
        see you again and recount their stories.
      </p>
      <p>
        Your Pal,
        <br />
        Frank &quot;The Yank&quot;
      </p>
    </Letter>
  )
}

export default IntroLetter
