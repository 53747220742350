import {Helmet} from 'react-helmet-async'
import {useNavigate} from 'react-router-dom'
import shuffleIn from '../sounds/shuffleIn'
import Button from './Button'
import ClueHotspot from './ClueHotspot'
import ClueText from './ClueText'
import HelperText from './HelperText'
import LevelTutorialDialog from './LevelTutorialDialog'

function OfficePage(): React.JSX.Element {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>Office</title>
      </Helmet>
      <h1 className="sr-only">Office</h1>
      <div className="absolute bottom-0 w-full text-center">
        <HelperText>Tap on the CLUE icons to explore this scene.</HelperText>
      </div>
      <div className="absolute left-[31%] top-[37%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot clue="air-bases" imageUrl="/air-bases-clue.webp">
          <p>
            The Allied Forces provided American pilots with{' '}
            <ClueText>air bases</ClueText> during the war, allowing U.S. forces
            to help secure the skies of Europe more effectively.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[88%] top-[35%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot
          clue="ltc-benjamin-davis"
          imageUrl="/ltc-benjamin-davis-clue.webp"
        >
          <p>
            Lieutenant Colonel <ClueText>Benjamin O. Davis Jr.</ClueText>, famed
            commander of the Tuskegee Airmen.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[27%] top-[82%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot clue="aviators-cap" imageUrl="/aviators-cap-clue.webp">
          <p>
            A World War II <ClueText>aviator helmet</ClueText>.
          </p>
          <p>
            These caps were originally designed for open cockpit fighters, but
            adapted and remained in common use for quite some time after closed
            cockpits were introduced.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[74%] top-[65%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot clue="flying-cross" imageUrl="/flying-cross-clue.webp">
          <p>
            A Distinguished <ClueText>Flying Cross</ClueText>, earned for a
            particularly dangerous bomber escort to Munich.
          </p>
          <p>
            Such runs ran parallel to the D-Day invasion, clearing the way for
            troops on the ground to advance.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[62%] top-[36%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot clue="p51-mustang" imageUrl="/p51-mustang-clue.webp">
          <p>
            A <ClueText>P51 Mustang</ClueText>, arguably the best American
            fighter plane in World War II.
          </p>
          <p>
            Its tail has been painted red -- a distinct marking of the all-Black
            unit known as the Tuskegee Airmen.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[53%] top-[82%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot
          clue="west-point-graduate"
          imageUrl="/west-point-graduate-clue.webp"
        >
          <p>
            A file on B. Davis, indicating that he&apos;s a{' '}
            <ClueText>West Point Graduate</ClueText>.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute left-[51%] top-[61%] -translate-x-1/2 -translate-y-1/2">
        <ClueHotspot
          clue="tuskegee-airmen"
          imageUrl="/tuskegee-airmen-clue.webp"
        >
          <p>
            A photo of the <ClueText>Tuskegee Airmen</ClueText>.
          </p>
          <p>
            This landmark all-Black unit collectively flew over 15,000 sorties
            (attacks) during the war, many of them in Europe.
          </p>
        </ClueHotspot>
      </div>
      <div className="absolute right-0 top-0 m-2 flex items-start gap-5">
        <Button
          onClick={() => {
            navigate('/office/letter')
            shuffleIn.play()
          }}
        >
          VIEW LETTER
        </Button>
      </div>
      <LevelTutorialDialog />
    </>
  )
}

export default OfficePage
