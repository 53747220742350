import * as Sentry from '@sentry/react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import type {Clue} from '../domain/clue'
import ClueState from '../domain/clueState'
import type Level from '../domain/level'

type ClueStates = Partial<Record<Clue, ClueState>>

type ClueActions = Readonly<{
  investigateClue: (clue: Clue) => void
  tagOrUntagClue: (clue: Clue, tagged: boolean) => void
  checkAnswer: () => boolean
}>

type Props = Readonly<{
  level: Level
  initialClueStates: Readonly<ClueStates>
  correctClues: ReadonlyArray<Clue>
  children: React.ReactNode
}>

const ClueStatesContext = createContext<ClueStates | undefined>(undefined)

const CorrectCluesContext = createContext<ReadonlyArray<Clue> | undefined>(
  undefined,
)

const ClueActionsContext = createContext<ClueActions | undefined>(undefined)

function ClueStateProvider({
  level,
  initialClueStates,
  correctClues,
  children,
}: Props): React.JSX.Element {
  const storageKey = `${level}-clue-states`
  const [clueStates, setClueStates] = useState(() => {
    try {
      const persistedClueStates = sessionStorage.getItem(storageKey)
      if (persistedClueStates != null) {
        return JSON.parse(persistedClueStates) as unknown as ClueStates
      }
    } catch (err) {
      // Squash and log error.
      Sentry.captureException(err)
      console.error(err)
    }
    return initialClueStates
  })

  useEffect(() => {
    try {
      sessionStorage.setItem(storageKey, JSON.stringify(clueStates))
    } catch (err) {
      // Squash and log error.
      Sentry.captureException(err)
      console.error(err)
    }
  }, [storageKey, clueStates])

  const investigateClue = useCallback(
    (clue: Clue) => {
      setClueStates((clueStates) => ({
        ...clueStates,
        [clue]: ClueState.investigate(clueStates[clue] ?? 'NOT_INVESTIGATED'),
      }))
      gtag('event', 'investigate_clue', {clue, level})
    },
    [level],
  )

  const tagOrUntagClue = useCallback(
    (clue: Clue, tagged: boolean) => {
      setClueStates((clueStates) => ({
        ...clueStates,
        [clue]: tagged
          ? ClueState.tag(clueStates[clue] ?? 'NOT_INVESTIGATED')
          : ClueState.untag(clueStates[clue] ?? 'NOT_INVESTIGATED'),
      }))
      if (tagged) {
        gtag('event', 'tag_clue', {clue, level})
      } else {
        gtag('event', 'untag_clue', {clue, level})
      }
    },
    [level],
  )

  const checkAnswer = useCallback(() => {
    const taggedClues = Object.entries(clueStates)
      .filter(([, state]) => ClueState.tagged(state))
      .map(([clue]) => clue as Clue)
    setClueStates((clueStates) => {
      const checkedClueStates = {...clueStates}
      for (const taggedClue of taggedClues) {
        const clueState = clueStates[taggedClue] ?? 'NOT_INVESTIGATED'
        checkedClueStates[taggedClue] = correctClues.includes(taggedClue)
          ? ClueState.markCorrect(clueState)
          : ClueState.markIncorrect(clueState)
      }
      return checkedClueStates
    })
    let correctCluesCount = 0
    let incorrectCluesCount = 0
    for (const taggedClue of taggedClues) {
      if (correctClues.includes(taggedClue)) {
        correctCluesCount += 1
      } else {
        incorrectCluesCount += 1
      }
    }
    const correct =
      taggedClues.length === correctClues.length &&
      correctCluesCount === correctClues.length
    gtag('event', 'check_answer', {
      level,
      correct,
      tagged_clues: taggedClues.length,
      correct_clues: correctCluesCount,
      incorrect_clues: incorrectCluesCount,
    })
    return correct
  }, [level, clueStates, correctClues])

  const clueActions = useMemo(
    () => ({investigateClue, tagOrUntagClue, checkAnswer}),
    [investigateClue, tagOrUntagClue, checkAnswer],
  )

  return (
    <CorrectCluesContext.Provider value={correctClues}>
      <ClueActionsContext.Provider value={clueActions}>
        <ClueStatesContext.Provider value={clueStates}>
          {children}
        </ClueStatesContext.Provider>
      </ClueActionsContext.Provider>
    </CorrectCluesContext.Provider>
  )
}

export function useClueState(clue: Clue): ClueState | undefined {
  const context = useContext(ClueStatesContext)
  if (context === undefined) {
    throw new Error('useClueState must be used within a ClueStateProvider')
  }
  return context[clue]
}

export function useClueActions(): ClueActions {
  const context = useContext(ClueActionsContext)
  if (context === undefined) {
    throw new Error('useClueActions must be used within a ClueStateProvider')
  }
  return context
}

function useClueCount(predicate: (clue: ClueState) => boolean): number {
  const context = useContext(ClueStatesContext)
  if (context === undefined) {
    throw new Error('useClueCount must be used within a ClueStateProvider')
  }
  return Object.values(context).filter(predicate).length
}

export function useTaggedClueCount(): number {
  return useClueCount(ClueState.tagged)
}

export function useInvestigatedClueCount(): number {
  return useClueCount(ClueState.investigated)
}

export function useCorrectClueCount(): number {
  const context = useContext(CorrectCluesContext)
  if (context === undefined) {
    throw new Error(
      'useCorrectClueCount must be used within a ClueStateProvider',
    )
  }
  return context.length
}

export function useCheckedCorrectClues(): ReadonlyArray<Clue> {
  const context = useContext(ClueStatesContext)
  if (context === undefined) {
    throw new Error(
      'useCheckedCorrectClues must be used within a ClueStateProvider',
    )
  }
  return Object.entries(context)
    .filter(([, state]) => ClueState.correct(state))
    .map(([clue]) => clue as Clue)
}

export default ClueStateProvider
